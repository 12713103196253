body,
html {
  height: 100%;
  position: relative;
}
#ie10nomore {
  background: #ffffff;
  clear: both;
  display: none;
  height: 100%;
  left: 0;
  line-height: 21px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9999;
}
#ie10nomore .box {
  height: 250px;
  left: 50%;
  margin: -125px 0 0 -275px;
  position: relative;
  top: 50%;
  width: 550px;
}
#ie10nomore .browser {
  float: left;
  margin-top: 17px;
  width: 75px;
}
#ie10nomore h2 {
  color: #000000;
  font-family: Arial;
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 14px;
  padding: 0;
}
#ie10nomore p {
  color: #000000;
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
  margin: 0 0 14px;
}
#ie10nomore a {
  color: #000000;
  text-decoration: underline;
}
.nobreak {
  white-space: nowrap;
}
.nohyphens {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
}
.hideme {
  display: none !important;
}
::-moz-selection {
  background: #0766b1;
  color: #fff;
}
::selection {
  background: #0766b1;
  color: #fff;
}
* {
  -webkit-tap-highlight-color: rgba(7, 102, 177, 0.1);
  box-sizing: border-box;
}
.page {
  background: #fff;
}
html {
  -webkit-text-size-adjust: 100%;
          text-size-adjust: 100%;
}
body {
  letter-spacing: calc((1rem + 0.125
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
  font-size: 1rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: #fff;
  color: #000;
  font-family: "DroidSans", sans-serif;
  -webkit-font-feature-settings: "liga", "kern";
          font-feature-settings: "liga", "kern";
  -webkit-font-kerning: normal;
          font-kerning: normal;
  font-smoothing: antialiased;
  font-weight: 400;
  hyphenate-limit-chars: 6 3 2;
  hyphenate-limit-lines: 2;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  line-height: 1.64;
  margin: 0;
  text-rendering: geometricPrecision;
}
@media (min-width: 20rem) {
  body {
    font-size: calc(1rem + 0.125
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media (min-width: 78.125em) {
  body {
    font-size: 1.125rem;
  }
}
body.no_scroll {
  overflow: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
  word-wrap: break-word;
}
h1 {
  letter-spacing: calc((1.375rem + 0.625
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
  font-size: 1.375rem;
  color: #0766b1;
  font-family: "DroidSerif-Regular", sans-serif;
  font-weight: 400;
  line-height: 1.45;
  margin: 0 0 0.82em;
}
@media (min-width: 20rem) {
  h1 {
    font-size: calc(1.375rem + 0.625
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media (min-width: 78.125em) {
  h1 {
    font-size: 2rem;
  }
}
h1 .flag {
  letter-spacing: calc((1rem + 0.125
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
  font-size: 1rem;
  background-color: #eaeaea;
  color: #000;
  line-height: 3.19;
  padding: 0.41em 0.54666667em;
}
@media (min-width: 20rem) {
  h1 .flag {
    font-size: calc(1rem + 0.125
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media (min-width: 78.125em) {
  h1 .flag {
    font-size: 1.125rem;
  }
}
h2 {
  letter-spacing: calc((1.25rem + 0.375
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
  font-size: 1.25rem;
  color: #0766b1;
  font-family: "DroidSerif-Regular", sans-serif;
  font-weight: 400;
  line-height: 1.45;
  margin: 0 0 0.82em;
  padding-top: 0.82em;
}
@media (min-width: 20rem) {
  h2 {
    font-size: calc(1.25rem + 0.375
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media (min-width: 78.125em) {
  h2 {
    font-size: 1.625rem;
  }
}
.startpage h1,
h3 {
  letter-spacing: calc((1.1875rem + 0.1875
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 20);
  font-size: 1.1875rem;
  color: #0766b1;
  font-family: "DroidSerif-Regular", sans-serif;
  font-weight: 400;
  line-height: 1.45;
  margin: 0 0 0.82em;
  padding-top: 0.82em;
}
@media (min-width: 20rem) {
  .startpage h1,
  h3 {
    font-size: calc(1.1875rem + 0.1875
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media (min-width: 78.125em) {
  .startpage h1,
  h3 {
    font-size: 1.375rem;
  }
}
h4 {
  letter-spacing: calc((1.0625rem + 0.125
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
  font-size: 1.0625rem;
  color: #000;
  font-family: "DroidSerif-Regular", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.82em;
}
@media (min-width: 20rem) {
  h4 {
    font-size: calc(1.0625rem + 0.125
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media (min-width: 78.125em) {
  h4 {
    font-size: 1.1875rem;
  }
}
h5 {
  letter-spacing: calc((1rem + 0.0625
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
  font-size: 1rem;
  color: #000;
  font-family: "DroidSerif-Regular", sans-serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0;
}
@media (min-width: 20rem) {
  h5 {
    font-size: calc(1rem + 0.0625
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media (min-width: 78.125em) {
  h5 {
    font-size: 1.0625rem;
  }
}
h6 {
  height: 0;
  margin: 0;
  overflow: hidden;
  width: 0;
}
p {
  margin: 0 0 0.82em;
}
a {
  color: #0766b1;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a:active,
a.active {
  color: #000;
  outline: 0;
}
a:focus {
  outline: 0.1875rem solid rgba(7, 102, 177, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a:focus[data-focus-method="mouse"],
a:focus[data-focus-method="touch"] {
  outline: 0;
}
a:hover {
  text-decoration: none;
}
a[tabindex="-1"]:focus {
  outline: 0;
}
a[tabindex="-1"][id] {
  position: relative;
  top: -2.5rem;
}
a[tabindex="-1"][id]:after {
  content: "";
}
a.touch_link {
  color: #000;
  display: block;
  text-decoration: none;
}
a.touch_link .title {
  color: #0766b1;
  display: inline-block;
  margin-bottom: 0.41em;
  outline-offset: 0;
  padding: 0;
  text-decoration: underline;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a.touch_link .title.active {
  color: #000;
}
a.touch_link:focus {
  outline: 0;
}
a.touch_link:focus .title {
  outline: 0.1875rem solid rgba(7, 102, 177, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a.touch_link:focus[data-focus-method="mouse"] .title,
a.touch_link:focus[data-focus-method="touch"] .title {
  outline: 0;
}
a.touch_link:hover .title {
  text-decoration: none;
}
.button {
  border-radius: 0.625rem;
  font-size: 1rem;
  padding: 0 1.64em;
  background: #0766b1;
  border: 0.0625rem solid #065898;
  color: #fff;
  display: inline-block;
  text-decoration: none;
  -webkit-transition: background 300ms, border 300ms, color 300ms;
  transition: background 300ms, border 300ms, color 300ms;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.button > span {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  height: calc(3.25rem - (0.0625rem * 2));
  -webkit-box-pack: center;
          justify-content: center;
}
.button .icon {
  fill: #fff;
  height: 2rem;
  -webkit-transition: fill 300ms;
  transition: fill 300ms;
  width: 2rem;
}
.button .text {
  font-weight: 400;
  padding: 0.54666667em 0;
  white-space: nowrap;
}
.button .icon + .text {
  padding-left: 0.54666667em;
}
.button:focus {
  box-shadow: 0 0 0 0.1875rem rgba(7, 102, 177, 0.2);
  outline: 0;
  background: #0982e2;
  border-color: #065898;
  color: #fff;
}
.button:focus .icon {
  fill: #fff;
}
.button:hover {
  background: #0982e2;
  border-color: #0766b1;
  color: #fff;
}
.button:hover .icon {
  fill: #fff;
}
.button:active,
.button.active {
  box-shadow: 0 0 0.1875rem #054a80 inset;
  background: #0766b1;
  border: 0.0625rem solid #054a80;
  color: #fff;
  display: inline-block;
  text-decoration: none;
  -webkit-transition: background 300ms, border 300ms, color 300ms;
  transition: background 300ms, border 300ms, color 300ms;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.button:active > span,
.button.active > span {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  height: calc(3.25rem - (0.0625rem * 2));
  -webkit-box-pack: center;
          justify-content: center;
}
.button:active .icon,
.button.active .icon {
  fill: #fff;
  height: 2rem;
  -webkit-transition: fill 300ms;
  transition: fill 300ms;
  width: 2rem;
}
.button:active .text,
.button.active .text {
  font-weight: 400;
  padding: 0.54666667em 0;
  white-space: nowrap;
}
.button:active .icon + .text,
.button.active .icon + .text {
  padding-left: 0.54666667em;
}
.button.button_black {
  background: #575757;
  border: 0.0625rem solid #4a4a4a;
  color: #fff;
  display: inline-block;
  text-decoration: none;
  -webkit-transition: background 300ms, border 300ms, color 300ms;
  transition: background 300ms, border 300ms, color 300ms;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.button.button_black > span {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  height: calc(3.25rem - (0.0625rem * 2));
  -webkit-box-pack: center;
          justify-content: center;
}
.button.button_black .icon {
  fill: #fff;
  height: 2rem;
  -webkit-transition: fill 300ms;
  transition: fill 300ms;
  width: 2rem;
}
.button.button_black .text {
  font-weight: 400;
  padding: 0.54666667em 0;
  white-space: nowrap;
}
.button.button_black .icon + .text {
  padding-left: 0.54666667em;
}
.button.button_black:focus {
  box-shadow: 0 0 0 0.1875rem rgba(7, 102, 177, 0.2);
  outline: 0;
  background: #717171;
  border-color: #4a4a4a;
  color: #fff;
}
.button.button_black:focus .icon {
  fill: #fff;
}
.button.button_black:hover {
  background: #717171;
  border-color: #575757;
  color: #fff;
}
.button.button_black:hover .icon {
  fill: #fff;
}
.button_special > span {
  height: 4.1875rem !important;
}
.highlighted {
  -webkit-animation: highlighted_anchor 900ms;
          animation: highlighted_anchor 900ms;
}
strong {
  font-weight: 700;
}
em {
  font-style: normal;
}
blockquote {
  margin: 0 0 0.82em 1ch;
  text-indent: -1ch;
}
blockquote:before {
  content: "\201E";
}
blockquote:after {
  content: "\201C";
}
small {
  font-size: 85%;
}
sup {
  font-size: 85%;
  line-height: 1;
  position: relative;
  top: -0.3125rem;
}
sub {
  bottom: -0.125rem;
  font-size: 85%;
  line-height: 1;
  position: relative;
}
.table {
  -webkit-overflow-scrolling: touch;
  margin-bottom: 1.64em;
  overflow-y: auto;
}
* + .table {
  margin-top: 1.64em;
}
table {
  border-bottom: 0.0625rem solid #d1d1d1;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  width: 100%;
}
th {
  font-weight: 700;
  text-align: left;
}
td,
th {
  border-top: 0.0625rem solid #d1d1d1;
  padding: 0.54666667em;
  -webkit-transition: background 300ms;
  transition: background 300ms;
  vertical-align: top;
}
ol {
  counter-reset: list;
  list-style: none;
  margin: 0 0 0.82em;
  padding: 0;
}
ol > li {
  padding-left: 1.64em;
  position: relative;
}
ol > li:before {
  color: #0766b1;
  content: counter(list) ".";
  counter-increment: list;
  font-weight: 700;
  left: 0;
  line-height: 1.64;
  position: absolute;
  text-align: right;
  width: 1.23em;
}
ol ol,
ol ul {
  margin: 0;
}
ul {
  list-style: url("../images/list_style_image_rectangle.svg");
  margin: 0 0 0.82em;
  padding-left: 1.23em;
}
ul > li {
  padding-left: 0.41em;
}
ul ol,
ul ul {
  margin: 0;
}
.defined_list dl {
  border-bottom: 0.0625rem solid #d1d1d1;
  margin: 0.82em 0;
}
@media (min-width: 35em) {
  .defined_list dl {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
}
.defined_list dt {
  border-top: 0.0625rem solid #d1d1d1;
  font-weight: 700;
  padding: 0.82em 0;
}
@media (min-width: 35em) {
  .defined_list dt {
    -webkit-box-flex: 0;
            flex: 0 0 40%;
    max-width: 40%;
    padding: 0.82em 0.82em 0.82em 0;
  }
}
.defined_list dd {
  margin: 0;
}
@media (min-width: 35em) {
  .defined_list dd {
    border-top: 0.0625rem solid #d1d1d1;
    -webkit-box-flex: 0;
            flex: 0 0 60%;
    max-width: 60%;
    padding: 0.82em 0.54666667em 0 0;
  }
}
nav {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
nav ol {
  margin: 0;
}
nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
nav li {
  margin: 0;
  padding: 0;
}
nav li:before {
  display: none;
}
figure {
  margin: 0 0 1.64em;
}
figure img {
  display: block;
}
figure.center {
  margin-left: auto;
  margin-right: auto;
}
figure.floatleft {
  float: left;
  margin-right: 1.64em;
}
figure.floatright {
  float: right;
  margin-left: 1.64em;
}
figcaption {
  font-size: 85%;
  margin-top: 0.41em;
  text-align: left;
}
.img_container {
  overflow: hidden;
}
.img_container img,
.img_container svg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.img_resize {
  height: 0;
  position: relative;
  width: 100%;
}
img {
  border: 0;
  display: inline-block;
  height: auto;
  max-width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
svg {
  height: 100%;
  -webkit-transform: scale(1);
          transform: scale(1);
  width: 100%;
}
svg a:focus {
  outline: 0;
}
input,
select,
textarea,
button {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
}
label {
  cursor: pointer;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #000;
  box-shadow: 0 0 0 62.5rem #fff inset, 0 0 0.1875rem #0766b1 inset, 0 0 0 0.1875rem rgba(7, 102, 177, 0.2) !important;
}
input::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}
.form_disclaimer {
  background: #eaeaea;
  border-radius: 0.625rem;
  color: #000;
  font-size: 85%;
  margin-bottom: 0.82em;
  padding: 1.09333333em 1.64em;
  text-align: center;
}
.form_disclaimer a {
  display: block;
}
::-webkit-input-placeholder {
  color: #404040;
  -webkit-user-select: none;
          user-select: none;
}
:-ms-input-placeholder {
  color: #404040;
  -ms-user-select: none;
      user-select: none;
}
::placeholder {
  color: #404040;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.required_asterisk {
  color: #0766b1;
}
.required_description {
  font-size: 85%;
}
.error_warning {
  background: #0766b1;
  color: #fff;
  display: none;
  font-weight: 700;
  margin-right: 0.41em;
  padding: 0 0.41em;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.error_warning.show {
  display: inline-block;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
.legend {
  letter-spacing: calc((1.1875rem + 0.25
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
  font-size: 1.1875rem;
  color: #000;
  font-family: "DroidSerif-Regular", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.82em;
  padding: 0.82em 0 0;
}
@media (min-width: 20rem) {
  .legend {
    font-size: calc(1.1875rem + 0.25
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media (min-width: 78.125em) {
  .legend {
    font-size: 1.4375rem;
  }
}
.label {
  display: inline-block;
  margin-bottom: 0.41em;
}
.input,
.textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #fff;
  border: 0.0625rem solid #d1d1d1;
  border-radius: 0.625rem;
  box-shadow: 0 0 0.1875rem #d1d1d1 inset;
  color: #000;
  margin: 0 0 1.09333333em;
  outline: 0;
  width: 100%;
}
.input[aria-invalid="true"],
.textarea[aria-invalid="true"] {
  border-color: #0766b1;
}
.input[aria-invalid="true"]:focus,
.textarea[aria-invalid="true"]:focus {
  border-color: #0766b1;
  box-shadow: 0 0 0.1875rem #0766b1 inset, 0 0 0 0.1875rem rgba(7, 102, 177, 0.2);
  color: #000;
}
.input:focus,
.textarea:focus {
  border-color: #0766b1;
  box-shadow: 0 0 0.1875rem #0766b1 inset, 0 0 0 0.1875rem rgba(7, 102, 177, 0.2);
  color: #000;
}
.input {
  height: 3.25rem;
  padding: 0 0.82em;
  -webkit-transition: border 300ms;
  transition: border 300ms;
}
.textarea {
  line-height: 1.64;
  overflow: hidden;
  padding: 0.82em;
  resize: none;
  -webkit-transition: border 300ms, height 300ms;
  transition: border 300ms, height 300ms;
}
.file {
  display: -webkit-box;
  display: flex;
  flex-basis: 65%;
  height: 3.25rem;
  margin: 0 0 1.09333333em;
  position: relative;
}
.file.error .file_name {
  border-color: #0766b1;
}
.file .file_name {
  background: #fff;
  border: 0.0625rem solid #d1d1d1;
  border-radius: 0.625rem 0 0 0.625rem;
  box-shadow: 0 0 0.1875rem #d1d1d1 inset;
  color: #000;
  -webkit-box-flex: 0;
          flex: 0 1 100%;
  height: 3.25rem;
  line-height: calc(3.25rem - (0.0625rem * 2));
  overflow: hidden;
  padding: 0 0.82em;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 6.875rem;
}
.file .file_button {
  border-radius: 0 0.625rem 0.625rem 0;
  -webkit-box-flex: 1;
          flex: 1 0 auto;
  padding: 0 1.64em;
  z-index: 10;
  background: #0766b1;
  border: 0.0625rem solid #065898;
  color: #fff;
  display: inline-block;
  text-decoration: none;
  -webkit-transition: background 300ms, border 300ms, color 300ms;
  transition: background 300ms, border 300ms, color 300ms;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.file .file_button > span {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  height: calc(3.25rem - (0.0625rem * 2));
  -webkit-box-pack: center;
          justify-content: center;
}
.file .file_button .icon {
  fill: #fff;
  height: 2rem;
  -webkit-transition: fill 300ms;
  transition: fill 300ms;
  width: 2rem;
}
.file .file_button .text {
  font-weight: 400;
  padding: 0.54666667em 0;
  white-space: nowrap;
}
.file .file_button .icon + .text {
  padding-left: 0.54666667em;
}
.file .file_button.focus {
  box-shadow: 0 0 0 0.1875rem rgba(7, 102, 177, 0.2);
  background: #0982e2;
  border-color: #065898;
  color: #fff;
}
.file .file_button.focus .icon {
  fill: #fff;
}
.file [type="file"] {
  bottom: 0;
  color: transparent;
  cursor: pointer;
  display: block;
  height: 3.25rem;
  left: 0;
  margin: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 20;
}
.file [type="file"]:hover ~ .file_button {
  background: #0982e2;
  border-color: #0766b1;
  color: #fff;
}
.file [type="file"]:hover ~ .file_button .icon {
  fill: #fff;
}
.file [type="file"]:active ~ .file_button {
  box-shadow: 0 0 0.1875rem #054a80 inset;
  background: #0766b1;
  border: 0.0625rem solid #054a80;
  color: #fff;
  display: inline-block;
  text-decoration: none;
  -webkit-transition: background 300ms, border 300ms, color 300ms;
  transition: background 300ms, border 300ms, color 300ms;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.file [type="file"]:active ~ .file_button > span {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  height: calc(3.25rem - (0.0625rem * 2));
  -webkit-box-pack: center;
          justify-content: center;
}
.file [type="file"]:active ~ .file_button .icon {
  fill: #fff;
  height: 2rem;
  -webkit-transition: fill 300ms;
  transition: fill 300ms;
  width: 2rem;
}
.file [type="file"]:active ~ .file_button .text {
  font-weight: 400;
  padding: 0.54666667em 0;
  white-space: nowrap;
}
.file [type="file"]:active ~ .file_button .icon + .text {
  padding-left: 0.54666667em;
}
.control {
  -webkit-box-align: start;
          align-items: flex-start;
  display: -webkit-box;
  display: flex;
  margin: 0.41em 0.82em 0.82em 0;
}
.control.checkbox input:checked ~ .indicator .icon {
  opacity: 1;
  visibility: visible;
}
.control input {
  border: 0;
  cursor: pointer;
  height: 1.5rem;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 1.5rem;
  z-index: 10;
}
.control input[aria-invalid="true"] ~ .indicator {
  border-color: #0766b1;
}
.control input[aria-invalid="true"]:focus ~ .indicator {
  border-color: #0766b1;
  box-shadow: 0 0 0.1875rem #0766b1 inset, 0 0 0 0.1875rem rgba(7, 102, 177, 0.2);
  color: #000;
}
.control input:focus ~ .indicator {
  border-color: #0766b1;
  box-shadow: 0 0 0.1875rem #0766b1 inset, 0 0 0 0.1875rem rgba(7, 102, 177, 0.2);
  color: #000;
}
.control .indicator_wrapper {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  flex-shrink: 0;
  margin-right: 0.82em;
  position: relative;
}
.control .indicator {
  background: #fff;
  border: 0.0625rem solid #d1d1d1;
  border-radius: 0.625rem;
  height: 1.375rem;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 1.375rem;
}
.control .indicator .icon {
  fill: #000;
  height: 1.25rem;
  opacity: 0;
  position: absolute;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
  visibility: hidden;
  width: 1.25rem;
}
.select_wrapper {
  display: block;
}
.select {
  background: #fff;
  border: 0.0625rem solid #d1d1d1;
  border-radius: 0.625rem;
  box-shadow: 0 0 0.1875rem #d1d1d1 inset;
  color: #000;
  cursor: pointer;
  display: block;
  margin: 0 0 1.09333333em;
  padding: 0 0.82em;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 100%;
}
.select:before {
  border: 0.625rem solid transparent;
  border-top-color: #000;
  content: "";
  display: inline-block;
  height: 0;
  margin-top: -0.3125rem;
  pointer-events: none;
  position: absolute;
  right: 0.82em;
  top: 50%;
  width: 0;
}
.select.error {
  border-color: #0766b1;
}
.select.error.focus {
  border-color: #0766b1;
  box-shadow: 0 0 0.1875rem #0766b1 inset, 0 0 0 0.1875rem rgba(7, 102, 177, 0.2);
  color: #000;
}
.select.focus {
  border-color: #0766b1;
  box-shadow: 0 0 0.1875rem #0766b1 inset, 0 0 0 0.1875rem rgba(7, 102, 177, 0.2);
  color: #000;
}
.select select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  color: #000;
  cursor: pointer;
  height: calc(3.25rem - (0.0625rem * 2));
  margin: 0;
  outline: 0;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.select select::-ms-expand {
  display: none;
}
.select select:focus:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
.select select:focus::-ms-value {
  background: none;
  color: #000;
}
@media (min-width: 35em) {
  .formular .button_wrapper {
    text-align: right;
  }
}
button {
  cursor: pointer;
  height: 3.25rem;
  margin: 0.82em 0;
  outline: 0;
  overflow: hidden;
  padding: 0 1.64em;
  width: 100%;
}
@media (min-width: 35em) {
  button {
    width: auto;
  }
}
button::-moz-focus-inner {
  border: 0;
}
@-webkit-keyframes header_image {
  0% {
    -webkit-transform: scale(1.08);
            transform: scale(1.08);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes header_image {
  0% {
    -webkit-transform: scale(1.08);
            transform: scale(1.08);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
@keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
@-webkit-keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(7, 102, 177, 0.1);
    box-shadow: 0 0 0 0.625rem rgba(7, 102, 177, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(7, 102, 177, 0.1);
    box-shadow: 0 0 0 0.625rem rgba(7, 102, 177, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@-webkit-keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@-webkit-keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@media screen {
  .page {
    min-width: 20rem;
  }
  .container {
    margin: 0 auto;
    max-width: 87.5rem;
    min-width: 20rem;
    padding: 0 0.9375rem;
  }
  .row {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: wrap;
    margin-left: -0.46875rem;
    margin-right: -0.46875rem;
  }
  .row.around_xsmall {
    justify-content: space-around;
  }
  .row.between_xsmall {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xsmall {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xsmall {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xsmall {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xsmall {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xsmall {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xsmall {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xsmall {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
  .row.no_column_margin {
    margin-left: 0;
    margin-right: 0;
  }
  .row.no_column_margin > [class*="span"] {
    padding-left: 0;
    padding-right: 0;
  }
  .row.half_column_margin {
    margin-left: -0.234375rem;
    margin-right: -0.234375rem;
  }
  .row.half_column_margin > [class*="span"] {
    padding-left: 0.234375rem;
    padding-right: 0.234375rem;
  }
  [class*="span"] {
    flex-basis: 100%;
    max-width: 100%;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
  }
  [class*="span"].span_column {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .span1_xsmall {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xsmall {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xsmall {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xsmall {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xsmall {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xsmall {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xsmall {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xsmall {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xsmall {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xsmall {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xsmall {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xsmall {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xsmall {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xsmall {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xsmall {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xsmall {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xsmall {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xsmall {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xsmall {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xsmall {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xsmall {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xsmall {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xsmall {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xsmall {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
  header {
    position: relative;
    z-index: 1;
  }
  header .author_ver {
    display: inline-block;
    fill: #000;
    height: 4.6875rem;
    line-height: 4.6875rem;
    margin-top: 6.25rem;
    position: absolute;
    right: 0.1875rem;
    top: 100%;
    width: 0.5625rem;
  }
  header .one {
    background-color: #0766b1;
  }
  header .one .container {
    display: -webkit-box;
    display: flex;
  }
  header .one .container .company_logo {
    max-width: 16.25rem;
    background-color: #fff;
    display: block;
    margin-right: 1.312em;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    width: 100%;
  }
  header .one .container .company_logo:focus,
  header .one .container .company_logo:hover {
    -webkit-transform: scale(1.04);
            transform: scale(1.04);
  }
  header .one .logo_etl {
    -webkit-box-align: center;
            align-items: center;
    background-color: #fff;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    margin-left: 0;
    margin-right: 0;
    -webkit-transition: 300ms;
    transition: 300ms;
    width: 100%;
  }
  header .one .logo_etl a {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  header .one .logo_etl a:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  header .one .logo_etl a img {
    border: 6px solid #fff;
    display: block;
    height: auto;
    max-width: 5.9375rem;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  header .two {
    position: relative;
  }
  header .two::after {
    background-color: #0766b1;
    bottom: -0.1875rem;
    content: "";
    height: 0.3125rem;
    left: 0;
    position: absolute;
    right: 0;
  }
  header .two .slider {
    line-height: 0;
    position: relative;
    width: 100%;
  }
  header .two .slider .slide {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    -webkit-transition: opacity 600ms ease-in-out;
    transition: opacity 600ms ease-in-out;
    width: 100%;
    z-index: 0;
  }
  header .two .slider .slide img {
    position: relative;
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 100%;
  }
  header .two .slider .slide:first-child {
    position: relative;
  }
  header .two .slider .slide.show {
    opacity: 1;
  }
  header .two .slider .slide.show img {
    -webkit-animation-duration: 3s;
            animation-duration: 3s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-name: header_image;
            animation-name: header_image;
  }
  header .two .slider .slogan {
    padding: 1.312em 1.968em 1.312em 0;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: 2222;
  }
  header .two .slider .slogan h2 {
    letter-spacing: calc((1.125rem + 1.5
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
    font-size: 1.125rem;
    margin: 0;
    padding: 0.082em 1.875rem;
    position: relative;
    z-index: 0;
  }
  header .two .slider .slogan h2::after {
    background-color: rgba(255, 255, 255, 0.9);
    bottom: 0;
    content: "";
    left: -62.4375rem;
    position: absolute;
    right: -6.25rem;
    top: 0;
    z-index: -1;
  }
  header .two .slider .slogan h2 strong {
    color: #8e0707;
    font-weight: 400;
  }
  header .two .slider .slogan h3 {
    letter-spacing: calc((0.875rem + 0.5
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 20);
    font-size: 0.875rem;
    color: #fff;
    margin: 0;
    padding: 0.82em 1.312em 0.82em 1.875rem;
    position: relative;
    z-index: 0;
  }
  header .two .slider .slogan h3::after {
    background-color: rgba(102, 102, 102, 0.8);
    bottom: 0;
    content: "";
    left: -12.5rem;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
  main {
    display: block;
  }
  .breadcrumbs {
    display: none;
    flex-wrap: wrap;
    margin-bottom: 0.82em;
  }
  .breadcrumbs p {
    margin: 0 0.41em 0 0;
  }
  .breadcrumbs ol {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .breadcrumbs .separator {
    margin: 0 0.41em;
  }
  .contact_wrapper {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .contact_wrapper p {
    margin-right: 2.46em;
  }
  .contact_wrapper .address > span {
    display: block;
  }
  .contact {
    display: -webkit-box;
    display: flex;
  }
  .contact strong {
    font-weight: 400;
  }
  main .contact strong {
    min-width: 5rem;
  }
  .address_vcard_button {
    display: -webkit-inline-box;
    display: inline-flex;
    margin: 0.41em 0 0.82em 0;
  }
  .address_vcard_button .button {
    width: 100%;
  }
  .address_vcard_button .button .icon {
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  .address_vcard_button .button:focus .icon,
  .address_vcard_button .button:hover .icon {
    -webkit-transform: rotateY(360deg);
            transform: rotateY(360deg);
  }
  .address_call_button {
    display: -webkit-inline-box;
    display: inline-flex;
    margin: 0.41em 0.41em 0.82em 0;
  }
  .address_call_button .button {
    width: 100%;
  }
  .address_call_button .button .icon {
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  .address_call_button .button:focus .icon,
  .address_call_button .button:hover .icon {
    -webkit-transform: rotate(-18deg);
            transform: rotate(-18deg);
  }
  .opening_times .title {
    padding-top: 0.82em;
  }
  .opening_times .entries {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .opening_times .entry {
    margin-right: 2.46em;
  }
  .download_overview,
  .infolist_overview {
    margin-bottom: 0.82em;
  }
  .download_overview .touch_link,
  .infolist_overview .touch_link {
    display: -webkit-box;
    display: flex;
    margin-bottom: 0.492em;
    padding: 0.492em 0 0 0;
    position: relative;
    z-index: 0;
  }
  .download_overview .touch_link::after,
  .infolist_overview .touch_link::after {
    background-color: #f3f3f3;
    bottom: 0;
    content: "";
    left: -0.492em;
    opacity: 0;
    position: absolute;
    right: -0.492em;
    top: 0;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    z-index: -1;
  }
  .download_overview .touch_link .title,
  .infolist_overview .touch_link .title {
    color: #0766b1;
    padding-bottom: 0;
    position: relative;
  }
  .download_overview .touch_link .icon,
  .infolist_overview .touch_link .icon {
    fill: #000;
    flex-shrink: 0;
    height: 3.75rem;
    margin-right: 0.82em;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    width: 3.75rem;
  }
  .download_overview .touch_link:focus::after,
  .infolist_overview .touch_link:focus::after,
  .download_overview .touch_link:hover::after,
  .infolist_overview .touch_link:hover::after {
    opacity: 1;
  }
  .download_overview .touch_link:focus .icon,
  .infolist_overview .touch_link:focus .icon,
  .download_overview .touch_link:hover .icon,
  .infolist_overview .touch_link:hover .icon {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  .useful_link_overview {
    align-content: stretch;
    -webkit-box-align: stretch;
            align-items: stretch;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
            justify-content: space-between;
    margin-bottom: 0.82em;
  }
  .useful_link_overview .overview_title {
    width: 100%;
  }
  .useful_link_overview .touch_link {
    margin-bottom: 0.82em;
    padding: 1.64em;
    position: relative;
    width: 100%;
    z-index: 0;
  }
  .useful_link_overview .touch_link::after {
    background-color: #e2e2e2;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transform-origin: left;
            transform-origin: left;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    z-index: -1;
  }
  .useful_link_overview .touch_link::before {
    background-color: #f3f3f3;
    border: 0.0625rem solid #e2e2e2;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -2;
  }
  .useful_link_overview .touch_link:hover::after,
  .useful_link_overview .touch_link:focus::after {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
  }
  .infolist_overview .touch_link:focus .icon,
  .infolist_overview .touch_link:hover .icon {
    -webkit-transform: rotate(-14deg);
            transform: rotate(-14deg);
  }
  .erinnerungsservice {
    margin-bottom: 1.64em;
  }
  .onlinetools_popup_overview {
    margin-bottom: 0.82em;
  }
  .onlinetools_popup_overview .entry {
    margin: 0 0 0.82em;
  }
  .onlinetools_popup_overview .description {
    display: block;
    margin-bottom: 0.82em;
  }
  .onlinetools_popup_overview .touch_link .title {
    margin: 0 0 0.41em;
    padding: 0;
  }
  .legal_notice_tax_aut_aab {
    margin-bottom: 0.82em;
    padding-top: 0.82em;
  }
  .legal_notice_tax_aut_aab [href$=".pdf"] {
    display: inline-block;
  }
  .legal_notice_tax_aut_aab [href$=".pdf"] > span {
    display: -webkit-box;
    display: flex;
  }
  .legal_notice_tax_aut_aab .icon {
    display: block;
    fill: #e44e4e;
    height: 1.5rem;
    margin-right: 0.656em;
    min-width: 1.5rem;
    width: 1.5rem;
  }
  .legal_notice_tax_aut {
    margin-bottom: 0.82em;
    padding-top: 0.82em;
  }
  .startpage h1 {
    text-align: center;
  }
  .startpage .intro {
    text-align: center;
  }
  .startpage .button_special {
    letter-spacing: calc((1.125rem + 0.1875
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
    font-size: 1.125rem;
    display: block;
    margin: 2.46em auto 4.1em auto;
    width: 13.75rem;
  }
  .overview {
    align-content: stretch;
    -webkit-box-align: stretch;
            align-items: stretch;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
            justify-content: space-between;
    margin: 2.296em 0 0 0;
  }
  .overview .touch_link {
    margin: 1.312em 0.82em;
    padding: 1.312em 0.82em;
    position: relative;
    width: 100%;
    z-index: 0;
  }
  .overview .touch_link::after {
    background-color: #eaeaea;
    bottom: 0;
    content: "";
    left: -0.82em;
    opacity: 1;
    position: absolute;
    right: -0.82em;
    top: -0.82em;
    -webkit-transition: background-color 300ms ease-in-out;
    transition: background-color 300ms ease-in-out;
    z-index: -1;
  }
  .overview .touch_link:focus::after,
  .overview .touch_link:hover::after {
    background-color: #0766b1;
  }
  .overview .touch_link:focus *,
  .overview .touch_link:hover * {
    color: #fff;
  }
  .steuernews_article_overview_startpage .button_imitation,
  .teaser .button_imitation {
    background: #0766b1;
    border: 0.0625rem solid #065898;
    color: #fff;
    display: inline-block;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    letter-spacing: calc((1.125rem + 0.1875
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
    font-size: 1.125rem;
    border-radius: 0.625rem;
    margin-top: 1.64em;
    padding: 0 1.64em;
  }
  .steuernews_article_overview_startpage .button_imitation > span,
  .teaser .button_imitation > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: calc(3.25rem - (0.0625rem * 2));
    -webkit-box-pack: center;
            justify-content: center;
  }
  .steuernews_article_overview_startpage .button_imitation .icon,
  .teaser .button_imitation .icon {
    fill: #fff;
    height: 2rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 2rem;
  }
  .steuernews_article_overview_startpage .button_imitation .text,
  .teaser .button_imitation .text {
    font-weight: 400;
    padding: 0.54666667em 0;
    white-space: nowrap;
  }
  .steuernews_article_overview_startpage .button_imitation .icon + .text,
  .teaser .button_imitation .icon + .text {
    padding-left: 0.54666667em;
  }
  .steuernews_article_overview_startpage .button_imitation > span,
  .teaser .button_imitation > span {
    height: 4.1875rem;
  }
  .steuernews_article_overview_startpage:focus .button_imitation,
  .teaser:focus .button_imitation,
  .steuernews_article_overview_startpage:hover .button_imitation,
  .teaser:hover .button_imitation {
    background: #0982e2;
    border-color: #0766b1;
    color: #fff;
  }
  .steuernews_article_overview_startpage:focus .button_imitation .icon,
  .teaser:focus .button_imitation .icon,
  .steuernews_article_overview_startpage:hover .button_imitation .icon,
  .teaser:hover .button_imitation .icon {
    fill: #fff;
  }
  .steuernews_article_overview_startpage p,
  .teaser p {
    -webkit-box-flex: 1;
            flex-grow: 1;
  }
  .teaer_special_startpage {
    align-content: stretch;
    -webkit-box-align: stretch;
            align-items: stretch;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
            justify-content: space-between;
    margin: 3.28em 0 1.64em 0;
    position: relative;
    z-index: 0;
  }
  .teaer_special_startpage::after {
    background-image: url("../images/blick_über_tal_im_nebel.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    content: "";
    left: calc((100vw - 100%) / -2);
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: -1;
  }
  .teaer_special_startpage .teaser {
    align-content: center;
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
            justify-content: center;
    padding: 1.64em 0;
    position: relative;
    width: 100%;
    z-index: 0;
  }
  .teaer_special_startpage .teaser .description {
    height: auto;
    margin: 0;
    overflow: hidden;
    -webkit-transition: height 300ms ease-in-out;
    transition: height 300ms ease-in-out;
    width: 100%;
  }
  .teaer_special_startpage .teaser .description .title,
  .teaer_special_startpage .teaser .description p {
    color: #fff;
    display: block;
    max-width: 26.25rem;
    text-align: center;
    width: 100%;
  }
  .teaer_special_startpage .teaser::after {
    bottom: 0;
    content: "";
    position: absolute;
    top: 0;
    -webkit-transition: background-color 300ms ease-in-out;
    transition: background-color 300ms ease-in-out;
    z-index: -1;
  }
  .teaer_special_startpage .teaser:nth-child(1)::after {
    background-color: rgba(122, 122, 122, 0.35);
    left: -62.4375rem;
    right: -62.4375rem;
  }
  .teaer_special_startpage .teaser:nth-child(2)::after {
    background-color: rgba(122, 122, 122, 0.7);
    left: -62.4375rem;
    right: -62.4375rem;
  }
  .teaer_special_startpage .teaser .icon {
    width: 6.25rem;
    height: 6.25rem;
    display: -webkit-box;
    display: flex;
    fill: #fff;
    height: auto;
    margin: 0;
    overflow: hidden;
    -webkit-transition: height 300ms ease-in-out;
    transition: height 300ms ease-in-out;
  }
  .teaer_special_startpage .teaser:focus:nth-child(1)::after,
  .teaer_special_startpage .teaser:hover:nth-child(1)::after {
    background-color: rgba(122, 122, 122, 0.5);
  }
  .teaer_special_startpage .teaser:focus:nth-child(2)::after,
  .teaer_special_startpage .teaser:hover:nth-child(2)::after {
    background-color: rgba(122, 122, 122, 0.85);
  }
  aside .aside_leistungs,
  aside .teaser {
    display: none;
    padding: 2.46em 1.64em 3.28em 1.64em;
    position: relative;
    text-align: center;
    z-index: 0;
  }
  aside .aside_leistungs.visible,
  aside .teaser.visible {
    display: block;
  }
  aside .aside_leistungs.newsletter_anmeldung::before,
  aside .teaser.newsletter_anmeldung::before,
  aside .aside_leistungs::after,
  aside .teaser::after {
    background-color: #e2e2e2;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: -3.75rem;
    top: 0;
    z-index: -1;
  }
  aside .aside_leistungs.newsletter_anmeldung::after,
  aside .teaser.newsletter_anmeldung::after {
    background-color: rgba(122, 122, 122, 0.7);
  }
  aside .aside_leistungs.newsletter_anmeldung::before,
  aside .teaser.newsletter_anmeldung::before {
    background-image: url("../images/blick_über_tal_im_nebel_stietenleiste.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -2;
  }
  aside .aside_leistungs.newsletter_anmeldung .title,
  aside .teaser.newsletter_anmeldung .title,
  aside .aside_leistungs.newsletter_anmeldung p,
  aside .teaser.newsletter_anmeldung p {
    color: #fff;
  }
  aside .aside_leistungs.newsletter_anmeldung .icon,
  aside .teaser.newsletter_anmeldung .icon {
    fill: #fff;
  }
  aside .aside_leistungs .title,
  aside .teaser .title {
    color: #000;
    margin-bottom: 0.82em;
  }
  aside .aside_leistungs .icon,
  aside .teaser .icon {
    height: 4.375rem;
    width: 4.375rem;
    display: -webkit-inline-box;
    display: inline-flex;
    fill: #0766b1;
    margin: 0 auto 1.64em;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  aside .aside_leistungs:nth-child(3)::after,
  aside .teaser:nth-child(3)::after {
    background-color: #f3f3f3;
  }
  aside .aside_leistungs:focus .icon,
  aside .teaser:focus .icon,
  aside .aside_leistungs:hover .icon,
  aside .teaser:hover .icon {
    -webkit-transform: rotateY(360deg);
            transform: rotateY(360deg);
  }
  aside .aside_leistungs ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: left;
  }
  aside .aside_leistungs ul > li {
    margin-bottom: 0.54666667em;
    padding: 0;
  }
  aside .aside_leistungs a {
    letter-spacing: calc((1.1875rem + 0.1875
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 20);
    font-size: 1.1875rem;
    position: relative;
    text-decoration: underline;
  }
  aside .aside_leistungs a:focus,
  aside .aside_leistungs a:hover {
    text-decoration: none;
  }
  footer .blue {
    background-color: #0766b1;
    padding: 2.788em 0;
  }
  footer .blue * {
    color: #fff;
  }
  footer .one {
    margin-bottom: 3.28em;
  }
  footer .one .bottom {
    margin-bottom: 2.952em;
  }
  footer .one .bottom .steuernews_article_overview_startpage,
  footer .one .bottom .teaser {
    align-content: center;
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
            justify-content: center;
    margin-bottom: 1.64em;
    padding-bottom: 1.64em;
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 0;
  }
  footer .one .bottom .steuernews_article_overview_startpage::before,
  footer .one .bottom .teaser::before {
    background-color: #0766b1;
    bottom: 0;
    content: "";
    height: 0.125rem;
    left: 12%;
    position: absolute;
    right: 12%;
    z-index: -1;
  }
  footer .one .bottom .steuernews_article_overview_startpage p,
  footer .one .bottom .teaser p {
    color: #000;
    margin-bottom: 2.46em;
    text-overflow: ellipsis;
    width: 100%;
  }
  footer .one .bottom .steuernews_article_overview_startpage p strong,
  footer .one .bottom .teaser p strong {
    color: #0766b1;
    font-weight: 400;
  }
  footer .one .bottom .steuernews_article_overview_startpage .button_imitation,
  footer .one .bottom .teaser .button_imitation {
    margin-top: auto;
  }
  footer .one .bottom .steuernews_article_overview_startpage .icon,
  footer .one .bottom .teaser .icon {
    fill: #0766b1;
    height: 3.75rem;
    margin-bottom: 0.984em;
    width: 3.75rem;
  }
  footer .one .bottom .steuernews_article_overview_startpage .icon.icon_people,
  footer .one .bottom .teaser .icon.icon_people {
    width: 9.375rem;
  }
  footer .one .bottom .steuernews_article_overview_startpage .title,
  footer .one .bottom .teaser .title {
    color: #000;
    margin: 0;
    padding: 0 0 0.82em 0;
  }
  footer .one .bottom .steuernews_article_overview_startpage {
    display: none;
  }
  footer .two .container {
    align-content: stretch;
    -webkit-box-align: stretch;
            align-items: stretch;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  footer .two .container > .address {
    width: 100%;
  }
  footer .two .container > .address a {
    position: relative;
    text-decoration: none;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  footer .two .container > .address a::after {
    background-color: #fff;
    bottom: 0;
    content: "";
    height: 0.0625rem;
    left: 0;
    opacity: 1;
    position: absolute;
    right: 0;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  footer .two .container > .address a:focus::after,
  footer .two .container > .address a:hover::after {
    left: 50%;
    opacity: 0;
    right: 50%;
  }
  footer .two .container p {
    letter-spacing: calc((0.9375rem + 0.125
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
    font-size: 0.9375rem;
  }
  footer .two .container .contact_wrapper p {
    margin-right: 1.476em;
  }
  footer .two .container .contact_wrapper p:last-child {
    margin-right: 0;
  }
  footer .two .container .contact_wrapper strong::after {
    content: " ";
    white-space: pre;
  }
  footer .two .container .company_name {
    letter-spacing: calc((1rem + 0.1875
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
    font-size: 1rem;
    line-height: 1.7;
    margin-bottom: 1.148em;
  }
  footer .three .related_links {
    align-content: center;
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
            justify-content: center;
    padding-top: 1.312em;
  }
  footer .three .related_links ul {
    align-content: center;
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
            justify-content: center;
  }
  footer .three .related_links ul li {
    border-left: 0.0625rem solid #fff;
    line-height: 1;
    padding: 0 0.492em;
  }
  footer .three .related_links ul li:first-child {
    border-left: 0;
    padding-left: 0;
  }
  footer .three .related_links ul li:last-child {
    padding-right: 0;
  }
  footer .three .related_links ul li a {
    letter-spacing: calc((0.9375rem + 0.0625
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
    font-size: 0.9375rem;
    line-height: 1;
    position: relative;
    text-decoration: none;
  }
  footer .three .related_links ul li a::after {
    background-color: #fff;
    bottom: 0;
    content: "";
    height: 0.0625rem;
    left: 0;
    opacity: 1;
    position: absolute;
    right: 0;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  footer .three .related_links ul li a:focus::after,
  footer .three .related_links ul li a:hover::after {
    left: 50%;
    opacity: 0;
    right: 50%;
  }
  .startpage .one .bottom {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .visible_for_screen_reader {
    border: 0;
    clip: rect(0.0625rem, 0.0625rem, 0.0625rem, 0.0625rem);
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  .bypass_blocks {
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    z-index: 6000;
  }
  .bypass_blocks li {
    display: block;
    width: 20rem;
  }
  .bypass_blocks a {
    background: #fff;
    color: #0766b1;
    display: inline-block;
    font-size: 85%;
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0.41em;
    position: absolute;
    text-decoration: underline;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  .bypass_blocks a:focus {
    color: #0766b1;
    height: auto;
    left: 0.41em;
    top: 0.41em;
    width: auto;
  }
  abbr {
    text-decoration: none;
  }
  [role="tablist"] {
    margin-bottom: 1.64em;
  }
  * + [role="tablist"] {
    margin-top: 1.64em;
  }
  [role="tab"] {
    -webkit-box-align: center;
            align-items: center;
    border-top: 0.0625rem solid #d1d1d1;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-size: 100%;
    margin: 0 0 0.41em;
    min-width: 100%;
    padding: 0.54666667em 0;
  }
  [role="tab"] .title {
    flex-basis: 100%;
    margin-right: 0.82em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  [role="tab"] .icon {
    display: block;
    fill: #0766b1;
    height: 1.5rem;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    width: 1.5rem;
  }
  [role="tab"][aria-expanded="true"] .icon {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  [role="tab"]:focus {
    outline: 0;
  }
  [role="tab"]:focus .title {
    color: #0766b1;
  }
  [role="tabpanel"] {
    height: auto;
    margin: 0;
    overflow: hidden;
  }
  [role="tabpanel"][aria-hidden="false"] {
    margin: 1.64em 0;
  }
  .csstransitions [role="tabpanel"].animated {
    -webkit-transition: margin 600ms, max-height 600ms;
    transition: margin 600ms, max-height 600ms;
  }
  .cookie_banner {
    -webkit-box-align: center;
            align-items: center;
    background: rgba(255, 255, 255, 0.9);
    border-top: 0.0625rem solid #d1d1d1;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 9000;
  }
  .cookie_banner p {
    flex-basis: 100%;
    margin: 0;
    padding: 1.09333333em 0.82em 1.09333333em 1.64em;
  }
  .cookie_banner .close {
    cursor: pointer;
    display: block;
    fill: #0766b1;
    flex-shrink: 0;
    height: 2.75rem;
    padding: 0.82em;
  }
  .dropdown_menu {
    display: none;
    margin-right: auto;
  }
  .dropdown_menu > ul {
    display: -webkit-box;
    display: flex;
    position: relative;
  }
  .dropdown_menu > ul > li {
    display: -webkit-box;
    display: flex;
    margin: 0 0.25rem 0 0;
    position: relative;
  }
  .dropdown_menu > ul > li.hover ul {
    opacity: 1;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  .dropdown_menu > ul > li.hover > a {
    background-color: #fff;
    color: #0766b1;
    outline: 0;
  }
  .dropdown_menu > ul > li > a {
    letter-spacing: calc((1.125rem + 0.1875
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
    font-size: 1.125rem;
    -webkit-box-align: center;
            align-items: center;
    color: #fff;
    display: -webkit-box;
    display: flex;
    font-family: "DroidSerif-Regular", sans-serif;
    font-weight: 400;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    letter-spacing: 0.06em;
    line-height: 1;
    padding: 0.82em 0.574em 0.656em 0.574em;
    position: relative;
    text-decoration: none;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    white-space: nowrap;
    z-index: 0;
  }
  .dropdown_menu > ul > li > a.active {
    background-color: #fff;
    color: #0766b1;
    position: relative;
    z-index: 0;
  }
  .dropdown_menu > ul > li > a.active::after {
    background-color: #0766b1;
    content: "";
    height: 0.4375rem;
    left: 0.625rem;
    position: absolute;
    right: 0.625rem;
    top: 0;
    z-index: -1;
  }
  .dropdown_menu > ul > li ul {
    background: #fff;
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.15);
    left: 0;
    min-width: 20rem;
    opacity: 0;
    padding: 1.148em 0.656em;
    position: absolute;
    top: 100%;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: top;
            transform-origin: top;
    -webkit-transition: all 210ms ease-in-out;
    transition: all 210ms ease-in-out;
    z-index: -1;
  }
  .dropdown_menu > ul > li ul a {
    letter-spacing: calc((1rem + 0.125
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
    font-size: 1rem;
    color: #0766b1;
    display: block;
    font-family: "DroidSerif-Regular", sans-serif;
    font-weight: 400;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    line-height: 1.4;
    padding: 0.41em 0.492em 0.41em 0.82em;
    position: relative;
    text-decoration: none;
    z-index: 0;
  }
  .dropdown_menu > ul > li ul a::after {
    background-color: #0766b1;
    bottom: 0.41em;
    content: "";
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0.41em;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: left center;
            transform-origin: left center;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    width: 0.375rem;
    z-index: -1;
  }
  .dropdown_menu > ul > li ul a.active {
    background-color: #0766b1;
    color: #fff;
  }
  .dropdown_menu > ul > li ul a.active::after {
    background-color: #fff;
  }
  .dropdown_menu > ul > li ul a:focus::after,
  .dropdown_menu > ul > li ul a:hover::after {
    opacity: 1;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  .dropdown_menu > ul > li ul a:hover {
    outline: 0;
  }
  .google_maps {
    margin: 0 0 0.82em;
  }
  .google_maps .map {
    background: #eaeaea;
    border: 0.0625rem solid #e2e2e2;
    height: 50vh;
    min-height: 25rem;
  }
  .google_maps .map img {
    max-width: none;
  }
  .google_maps .map .gm-style > button {
    width: auto;
  }
  .google_maps .map_print {
    display: none;
  }
  .google_maps .travel_mode {
    display: -webkit-box;
    display: flex;
    margin: 0 0 0.82em;
  }
  .google_maps .travel_mode input {
    opacity: 0;
    position: absolute;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio {
    z-index: 5;
    background: #d1d1d1;
    border-color: #c4c4c4;
    color: #000;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio .icon {
    fill: #000;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio {
    box-shadow: 0 0 0 0.1875rem rgba(7, 102, 177, 0.2);
    z-index: 15;
    background: #0982e2;
    border-color: #065898;
    color: #fff;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio .icon {
    fill: #fff;
  }
  .google_maps .travel_mode_entry {
    flex-basis: 100%;
    height: 3.25rem;
  }
  .google_maps .travel_mode_radio {
    cursor: pointer;
    position: relative;
    width: 100%;
    background: #fff;
    border: 0.0625rem solid #d1d1d1;
    color: #000;
    display: inline-block;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .google_maps .travel_mode_radio > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: calc(3.25rem - (0.0625rem * 2));
    -webkit-box-pack: center;
            justify-content: center;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #000;
    height: 2rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 2rem;
  }
  .google_maps .travel_mode_radio .text {
    font-weight: 400;
    padding: 0.54666667em 0;
    white-space: nowrap;
  }
  .google_maps .travel_mode_radio .icon + .text {
    padding-left: 0.54666667em;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #000;
    min-width: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
  }
  .google_maps .travel_mode_radio.driving {
    border-radius: 0.625rem 0 0 0.625rem;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.transit,
  .google_maps .travel_mode_radio.bicycling {
    border-left-width: 0;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.walking {
    border-left-width: 0;
    border-radius: 0 0.625rem 0.625rem 0;
  }
  .google_maps .warnings {
    background: #f6ecec;
    border-radius: 0.625rem;
    color: #0766b1;
    font-size: 85%;
    margin-bottom: 0.82em;
    padding: 1.09333333em 1.64em;
  }
  .google_maps .direction {
    margin: 0.82em 0;
  }
  .google_maps .button_wrapper {
    margin-top: auto;
  }
  .google_maps .panel_wrapper {
    padding-top: 0.82em;
  }
  .google_maps .steps {
    min-width: 100%;
  }
  .google_maps .step {
    border-top: 0.0625rem solid #d1d1d1;
    display: -webkit-box;
    display: flex;
  }
  .google_maps .step.last {
    border-bottom: 0.0625rem solid #d1d1d1;
  }
  .google_maps .step:before {
    font-size: 85%;
    padding: 0.82em 0;
  }
  .google_maps .instructions,
  .google_maps .distance {
    font-size: 85%;
    padding: 0.82em 0;
  }
  .google_maps .instructions {
    overflow: hidden;
    padding-left: 0.82em;
    width: 100%;
    word-wrap: break-word;
  }
  .google_maps .distance {
    min-width: 5rem;
    text-align: right;
  }
  .u_online_overview {
    margin: 2.952em 0 1.968em 0;
  }
  .u_online_overview .touch_link {
    height: 100%;
    position: relative;
    z-index: 0;
  }
  .u_online_overview .touch_link::after {
    background-color: #eaeaea;
    bottom: -0.82em;
    content: "";
    left: -0.82em;
    opacity: 1;
    position: absolute;
    right: -0.82em;
    top: -0.82em;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    z-index: -1;
  }
  .u_online_overview .touch_link:focus::after,
  .u_online_overview .touch_link:hover::after {
    background-color: #0766b1;
  }
  .u_online_overview .touch_link:focus *,
  .u_online_overview .touch_link:hover * {
    color: #fff;
  }
  .u_online_secure_login {
    margin-bottom: 1.64em;
  }
  .img_container.u_online_svg_2_small {
    max-width: 18.125rem;
  }
  .img_container.u_online_svg_2_small .svg_resize {
    padding-bottom: 634.48275862%;
  }
  .img_container.u_online_svg_2 {
    margin: 0 0 1.64em;
    max-width: 28.125rem;
  }
  .img_container.u_online_svg_2 .svg_resize {
    padding-bottom: 148.88888889%;
  }
  .u_online_svg_2_small {
    display: block;
    margin: 1.64em auto;
  }
  .u_online_svg_2 {
    display: none;
  }
  .u_online_2_headline {
    fill: #000;
    font-family: "DroidSans", sans-serif;
    font-size: 90%;
    text-anchor: middle;
  }
  .u_online_2_headline .strong {
    fill: #000;
    font-weight: 700;
  }
  .u_online_text {
    fill: #000;
    font-family: "DroidSans", sans-serif;
    font-size: 90%;
    text-anchor: middle;
  }
  .u_online_company_logo {
    fill: #000;
  }
  .u_online_warning_icon {
    fill: #000;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .u_online_check_icon,
  .u_online_euro_icon {
    fill: #000;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .u_online_icon {
    fill: #0766b1;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .u_online_button .normal {
    display: block;
  }
  .u_online_button .hover {
    display: none;
  }
  .u_online_button:hover .normal {
    display: none;
  }
  .u_online_button:hover .hover {
    display: block;
  }
  .u_online_button:hover .u_online_icon {
    fill: #0766b1;
  }
  .u_online_button:hover .u_online_check_icon,
  .u_online_button:hover .u_online_euro_icon,
  .u_online_button:hover .u_online_warning_icon {
    fill: #0766b1;
  }
  .u_online_button:hover .u_online_icon {
    fill: #000;
  }
  .u_online_lines {
    fill: #0766b1;
  }
  .u_online_benefits_flow h2 {
    margin-left: auto;
    margin-right: auto;
    max-width: 30em;
    text-align: center;
  }
  .u_online_benefits_flow .table_cell.text {
    margin: 0 auto;
    max-width: 20rem;
  }
  .u_online_benefits_flow .flow_text {
    display: none;
  }
  .u_online_benefits_flow .header {
    display: table;
    margin: 0.82em auto;
    max-width: 20rem;
    text-align: right;
  }
  .u_online_benefits_flow .header .text {
    display: table-cell;
    margin: 0;
    padding: 0 0.82em 0 0;
    vertical-align: middle;
  }
  .u_online_benefits_flow .header:after {
    background: url("../images/u_online/u_online_arrow_small.svg") no-repeat center center;
    content: "";
    display: table-cell;
    height: 4rem;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    width: 1.875rem;
  }
  .u_online_benefits_flow .footer {
    display: table;
    margin: 0.82em auto;
    max-width: 20rem;
  }
  .u_online_benefits_flow .footer .text {
    display: table-cell;
    margin: 0;
    padding: 0 0 0 0.82em;
    vertical-align: middle;
  }
  .u_online_benefits_flow .footer:before {
    background: url("../images/u_online/u_online_arrow_small.svg") no-repeat center center;
    content: "";
    display: table-cell;
    height: 4rem;
    width: 1.875rem;
  }
  .u_online_benefits_flow .description {
    margin: 0 auto;
  }
  .u_online_benefits_flow .description ul {
    list-style: none;
    margin: 1.64em;
  }
  .u_online_benefits_flow .description li {
    margin: 0.8em 0;
    padding-left: 2.125rem;
    position: relative;
  }
  .u_online_benefits_flow .description li:before {
    background-image: url("../images/u_online/u_online_check.svg");
    content: "";
    display: block;
    height: 1.5rem;
    left: 0;
    position: absolute;
    width: 1.5rem;
  }
  .u_online_benefits_flow .lock {
    background: #eaeaea;
    border-radius: 0.625rem;
    display: table;
    margin: 0 auto 0.82em;
    padding: 1.64em;
  }
  .u_online_benefits_flow .lock .text {
    display: table-cell;
    padding-left: 1.64em;
    vertical-align: middle;
  }
  .u_online_benefits_flow .lock:before {
    background: url("../images/u_online/u_online_lock.svg") no-repeat 50% 50%;
    content: "";
    display: table-cell;
    height: 1.75rem;
    width: 1.75rem;
  }
  .u_online_benefits_flow .benefits_button {
    border: 0;
    cursor: pointer;
    display: table;
    margin: 0 auto;
    min-width: 14.375rem;
    text-decoration: none;
  }
  .u_online_benefits_flow .benefits_button > span {
    display: table-cell;
  }
  .u_online_benefits_flow .benefits_button .text {
    border-bottom-width: 0.125rem;
    border-color: #0766b1;
    border-left-width: 0.0625rem;
    border-radius: 0.625rem 0 0 0.625rem;
    border-style: solid;
    border-top-width: 0.0625rem;
    color: #000;
    display: block;
    font-size: 130%;
    font-weight: 500;
    line-height: 3.25rem;
    margin-right: 0.1875rem;
    padding: 0 1.64em;
    text-align: center;
    text-transform: uppercase;
    -webkit-transition: border 300ms;
    transition: border 300ms;
  }
  .u_online_benefits_flow .benefits_button:after {
    background-color: #0766b1;
    background-image: url("../images/u_online/u_online_button_arrow.svg");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 60%;
    border-radius: 0 0.625rem 0.625rem 0;
    content: "";
    display: table-cell;
    height: 3.25rem;
    position: relative;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    vertical-align: middle;
    width: 3.25rem;
    z-index: 1;
  }
  .u_online_benefits_flow .benefits_button.visible .text {
    border-color: #000;
  }
  .u_online_benefits_flow .benefits_button.visible:after {
    background-color: #000;
    border-radius: 0.625rem 0 0 0.625rem;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .u_online_benefits_flow .benefits_button:hover .text {
    border-color: #054a80;
  }
  .u_online_benefits_flow .benefits_button:hover:after {
    background-color: #054a80;
  }
  .u_online_benefits_flow .flow_image {
    border: 0.25rem solid #0766b1;
    border-radius: 50%;
    box-sizing: content-box;
    height: 15.625rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    width: 15.625rem;
  }
  .u_online_benefits_flow .flow_image .text {
    background: rgba(7, 102, 177, 0.85);
    bottom: 0;
    color: #fff;
    left: 0;
    padding: 0.8em 0;
    position: absolute;
    text-align: center;
    width: 100%;
  }
  .u_online_benefits_flow .flow_image img {
    border-radius: 50%;
  }
  .amount {
    display: block;
    text-align: right;
    white-space: nowrap;
  }
  .multi_columns {
    -webkit-column-count: 2;
            column-count: 2;
    -webkit-column-gap: 1.64em;
            column-gap: 1.64em;
  }
  .multi_columns li {
    -webkit-column-break-inside: avoid;
            break-inside: avoid;
  }
  .infolist_top_link {
    align-content: center;
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 1.64em 0;
  }
  .infolist_top_link a {
    color: #000;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin: 0;
    position: relative;
    text-align: center;
    text-decoration: none;
  }
  .infolist_top_link a::after {
    background-color: #000;
    bottom: 0;
    content: "";
    height: 0.0625rem;
    left: 50%;
    opacity: 0;
    position: absolute;
    right: 50%;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  .infolist_top_link a .icon {
    display: block;
    fill: #0766b1;
    height: 2rem;
    margin: 0 auto;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    width: 2rem;
  }
  .infolist_top_link a:focus::after,
  .infolist_top_link a:hover::after {
    left: 20%;
    opacity: 1;
    right: 20%;
  }
  .infolist_top_link a:focus .icon,
  .infolist_top_link a:hover .icon {
    -webkit-transform: rotate(-90deg) scale(1.2);
            transform: rotate(-90deg) scale(1.2);
  }
  .disclaimer {
    background: #eaeaea;
    border-radius: 0.625rem;
    color: #000;
    font-size: 85%;
    margin-bottom: 0.82em;
    padding: 1.09333333em 1.64em;
    text-align: center;
  }
  .disclaimer a {
    display: block;
  }
  .infolist_download {
    -webkit-box-align: center;
            align-items: center;
    background: #eaeaea;
    border-radius: 0.625rem;
    color: #000;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
            justify-content: space-between;
    margin-bottom: 1.64em;
    padding: 1.64em;
  }
  .infolist_download .infolist_title {
    font-weight: 700;
  }
  .infolist_download p {
    margin-right: 1.64em;
  }
  .checklist_entry {
    border-top: 0.0625rem solid #d1d1d1;
    margin-bottom: 0.82em;
    padding-top: 0.82em;
  }
  .checklist_entry:first-child {
    border-top: 0;
    padding-top: 0;
  }
  .lexikon_liste a {
    text-decoration: none;
  }
  .lexikon_liste a:hover,
  .lexikon_liste a:focus {
    text-decoration: underline;
  }
  .kanzlei_im_netz {
    margin: 1.64em 0;
  }
  .kanzlei_im_netz a {
    border-bottom: 0.0625rem dotted #000;
    margin: 1.148em 0;
    position: relative;
    z-index: 0;
  }
  .kanzlei_im_netz a::after {
    background-color: #eaeaea;
    bottom: 0;
    content: "";
    left: -0.82em;
    opacity: 0;
    position: absolute;
    right: -0.82em;
    top: -0.82em;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    z-index: -1;
  }
  .kanzlei_im_netz a:hover::after,
  .kanzlei_im_netz a:focus::after {
    opacity: 1;
  }
  .kanzlei_im_netz .icon {
    background: #eaeaea;
    border-radius: 0.625rem;
    fill: #fff;
    float: left;
    height: 3.625rem;
    margin: 0 1.64em 0 0;
    padding: 0.3125rem;
    width: 3.625rem;
  }
  .kanzlei_im_netz .icon_facebook {
    background: #3664a2;
  }
  .kanzlei_im_netz .icon_twitter {
    background: #1da1f2;
  }
  .kanzlei_im_netz .icon_videos {
    background: #0766b1;
  }
  .kanzlei_im_netz .icon_mail {
    background: #0766b1;
  }
  .kanzlei_im_netz .icon_mail svg {
    padding: 0.3125rem;
  }
  .kanzlei_im_netz .icon svg {
    height: 3rem;
    width: 3rem;
  }
  .kanzlei_im_netz .description {
    display: block;
    overflow: hidden;
  }
  .toggle_offcanvas_menu {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
            flex-grow: 1;
    position: relative;
  }
  .toggle_offcanvas_menu label {
    -webkit-box-align: center;
            align-items: center;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
            flex-direction: row-reverse;
    font-family: "DroidSerif-Regular", sans-serif;
    font-size: 1.375rem;
    height: 3.25rem;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    margin-left: 0.82em;
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 10;
  }
  .toggle_offcanvas_menu label .icon {
    height: 1.5625rem;
    margin-left: 1.64em;
    position: relative;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: -webkit-transform 320ms 150ms;
    transition: -webkit-transform 320ms 150ms;
    transition: transform 320ms 150ms;
    transition: transform 320ms 150ms, -webkit-transform 320ms 150ms;
    width: 2.375rem;
  }
  .toggle_offcanvas_menu label .line {
    background: #fff;
    display: block;
    height: 0.1875rem;
    position: absolute;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    width: 100%;
  }
  .toggle_offcanvas_menu label .line_1 {
    opacity: 1;
    top: 0rem;
    -webkit-transition: background 300ms, top 300ms 300ms, -webkit-transform 300ms;
    transition: background 300ms, top 300ms 300ms, -webkit-transform 300ms;
    transition: background 300ms, top 300ms 300ms, transform 300ms;
    transition: background 300ms, top 300ms 300ms, transform 300ms, -webkit-transform 300ms;
    visibility: visible;
  }
  .toggle_offcanvas_menu label .line_2 {
    opacity: 1;
    top: 0.6875rem;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: visible;
  }
  .toggle_offcanvas_menu label .line_3 {
    bottom: 0rem;
    -webkit-transition: background 300ms, bottom 300ms 300ms, -webkit-transform 300ms;
    transition: background 300ms, bottom 300ms 300ms, -webkit-transform 300ms;
    transition: background 300ms, bottom 300ms 300ms, transform 300ms;
    transition: background 300ms, bottom 300ms 300ms, transform 300ms, -webkit-transform 300ms;
  }
  .toggle_offcanvas_menu label .text {
    color: #fff;
    line-height: 0;
    margin-top: 0.1875rem;
  }
  .toggle_offcanvas_menu a {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .toggle_offcanvas_menu .open_offcanvas_menu {
    display: block;
  }
  .toggle_offcanvas_menu .close_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .icon,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .icon {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line_1,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line_1 {
    top: 0.6875rem;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transition: background 300ms, top 300ms, -webkit-transform 300ms 300ms;
    transition: background 300ms, top 300ms, -webkit-transform 300ms 300ms;
    transition: background 300ms, top 300ms, transform 300ms 300ms;
    transition: background 300ms, top 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line_2,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line_2 {
    opacity: 0;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: hidden;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line_3,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line_3 {
    bottom: 0.6875rem;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transition: background 300ms, bottom 300ms, -webkit-transform 300ms 300ms;
    transition: background 300ms, bottom 300ms, -webkit-transform 300ms 300ms;
    transition: background 300ms, bottom 300ms, transform 300ms 300ms;
    transition: background 300ms, bottom 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu .open_offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu .open_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu .close_offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu .close_offcanvas_menu {
    display: block;
  }
  .offcanvas_menu_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
            flex-direction: row-reverse;
    min-height: 100vh;
    min-width: 20rem;
    overflow-x: hidden;
    position: relative;
    width: 100%;
    z-index: 5000;
  }
  .offcanvas_menu_wrapper .page {
    -webkit-box-flex: 1;
            flex: 1 0 100%;
    min-height: 100vh;
    position: relative;
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
  }
  .offcanvas_menu_wrapper .offcanvas_menu {
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.35);
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .offcanvas_menu_overlay {
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: hidden;
    z-index: 110;
  }
  .offcanvas_menu {
    background: #0766b1;
    -webkit-box-flex: 1;
            flex: 1 0 16.25rem;
    opacity: 0;
    padding: 1.64em 0;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    visibility: hidden;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li {
    padding: 0;
    position: relative;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li:last-child {
    border-bottom: 0;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    letter-spacing: calc((1.2rem + 0.1499999999999999
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
    font-size: 1.2rem;
    color: #fff;
    display: block;
    font-family: "DroidSerif-Regular", sans-serif;
    font-size: 1.25rem;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    line-height: 3.25rem;
    padding: 0.54666667em 1.64em;
    position: relative;
    text-decoration: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a::after {
    background-color: #fff;
    bottom: 0.41em;
    content: "";
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0.41em;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: left center;
            transform-origin: left center;
    -webkit-transition: opacity 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    transition: opacity 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
    transition: transform 300ms ease-in-out, opacity 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    width: 0.375rem;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a.active::after {
    background-color: #fff;
    opacity: 1;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a:focus::after,
  .offcanvas_menu .offcanvas_menu_content > ul > li > a:hover::after {
    opacity: 1;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li.expanded {
    background-color: #fff;
    padding: 0.82em 0;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li.expanded > a {
    color: #000;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li.expanded > a::after {
    background-color: #0766b1;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li.expanded > a ~ ul {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul {
    display: none;
    margin-bottom: 1.64em;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul:target {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul li {
    padding: 0 1.722em;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a {
    color: #000;
    display: block;
    font-family: "DroidSerif-Regular", sans-serif;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    margin-left: -0.656em;
    padding: 0.41em 2.132em 0.41em 0.656em;
    position: relative;
    text-decoration: none;
    -webkit-transition: margin 300ms ease-in-out;
    transition: margin 300ms ease-in-out;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a.active {
    background-color: #0766b1;
    color: #fff;
    margin-left: 0;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a::after {
    background-color: #0766b1;
    bottom: 0.41em;
    content: "";
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0.41em;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: left center;
            transform-origin: left center;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    width: 0.375rem;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a:focus,
  .offcanvas_menu .offcanvas_menu_content > ul ul a:hover {
    margin-left: 0;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a:focus::after,
  .offcanvas_menu .offcanvas_menu_content > ul ul a:hover::after {
    opacity: 1;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  .popup_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(255, 255, 255, 0.6);
    bottom: 0;
    left: 0;
    opacity: 0;
    padding: 1% 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: hidden;
    z-index: -1;
  }
  .popup_overlay.show {
    opacity: 1;
    visibility: visible;
    z-index: 9000;
  }
  .popup_header {
    padding: 0 0 0 1.64em;
  }
  .popup_close {
    background: #0766b1;
    border-radius: 0.625rem 0.625rem 0 0;
    float: right;
    height: 2.6rem;
    margin-right: 0.0625rem;
    padding: 0.46857143em;
    text-align: center;
    width: 3.25rem;
  }
  .popup_close .icon {
    fill: #fff;
    height: 1.5rem;
    width: 1.5rem;
  }
  .popup_content {
    height: 100%;
    margin: 0 auto;
    max-width: 87.5rem;
    opacity: 0;
    position: relative;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: hidden;
    z-index: 9500;
  }
  .popup_content.show {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    visibility: visible;
  }
  .popup_content .content {
    background: #fff;
    bottom: 0;
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.35);
    left: 0;
    position: absolute;
    right: 0;
    top: 2.6rem;
  }
  .popup_iframe {
    background: #fff;
    border: 0;
    height: 100%;
    opacity: 0;
    position: relative;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 100%;
    z-index: 1;
  }
  .popup_iframe.show {
    opacity: 1;
  }
  .preloader {
    display: block;
    position: relative;
  }
  .preloader .icon_spin {
    -webkit-animation: preloader_rotate 900ms linear infinite;
            animation: preloader_rotate 900ms linear infinite;
    display: block;
    fill: #d1d1d1;
    height: 3rem;
    left: 50%;
    margin: -1.5rem 0 0 -1.5rem;
    opacity: 1;
    position: absolute;
    top: 50%;
    -webkit-transition: 300ms;
    transition: 300ms;
    visibility: visible;
    width: 3rem;
  }
  .preloader .icon_spin.hide {
    opacity: 0;
    visibility: hidden;
  }
  .no-cssanimations .icon_spin {
    display: none;
  }
  .toggle_search_box {
    align-self: center;
    height: 2.125rem;
    position: relative;
    width: 2.125rem;
  }
  .toggle_search_box label {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
  }
  .toggle_search_box label:hover ~ a {
    fill: #0766b1;
  }
  .toggle_search_box a {
    fill: #000;
  }
  .toggle_search_box a .icon {
    align-self: center;
    display: -webkit-box;
    display: flex;
    fill: #fff;
    height: 2.125rem;
    padding: 0.25rem;
    width: 2.125rem;
  }
  .toggle_search_box .open_search_box {
    display: block;
  }
  .toggle_search_box .close_search_box {
    display: none;
  }
  .search_box {
    -webkit-backdrop-filter: blur(0.125rem);
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    padding: 0.82em;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: hidden;
    z-index: 9999;
  }
  .search_box .close_search_box_wrapper {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .search_box .close_search_box_wrapper label {
    background: rgba(255, 255, 255, 0.6);
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: hidden;
  }
  .search_box .close_search_box_wrapper .close_search_box {
    background: #0766b1;
    border: 0.0625rem solid #065898;
    border-radius: 50%;
    box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.7);
    fill: #fff;
    height: 2.75rem;
    padding: 0.25rem;
    position: absolute;
    right: 1.64em;
    top: 1.64em;
    width: 2.75rem;
  }
  .search_box .close_search_box_wrapper .close_search_box .icon {
    display: block;
    height: 2.125rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 2.125rem;
  }
  .search_box .close_search_box_wrapper .close_search_box:focus {
    background: #0982e2;
    border-color: #065898;
    outline: 0;
  }
  .search_box form[role="search"] {
    background: #fff;
    border: 0.625rem solid #fff;
    border-radius: 0.625rem;
    box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.5);
    display: -webkit-box;
    display: flex;
    opacity: 0;
    position: relative;
    -webkit-transform: translateY(-6.25rem);
            transform: translateY(-6.25rem);
    -webkit-transition: none;
    transition: none;
    visibility: hidden;
    width: 37.5rem;
    z-index: 1000;
  }
  .search_box form[role="search"] button {
    -webkit-box-flex: 0;
            flex: 0 1 3.75rem;
    height: auto;
    padding: 0 0.41em;
  }
  .search_box input[type="search"] {
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    font-size: 125%;
  }
  .search_box_state {
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    visibility: hidden;
  }
  .search_box_state:checked ~ .search_box,
  .search_box_state:target ~ .search_box {
    opacity: 1;
    visibility: visible;
  }
  .search_box_state:checked ~ .search_box .close_search_box_wrapper label,
  .search_box_state:target ~ .search_box .close_search_box_wrapper label {
    opacity: 1;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: visible;
  }
  .search_box_state:checked ~ .search_box form[role="search"],
  .search_box_state:target ~ .search_box form[role="search"] {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
    visibility: visible;
  }
  .search_box_state:checked ~ .offcanvas_menu_wrapper .toggle_search_box .open_search_box,
  .search_box_state:target ~ .offcanvas_menu_wrapper .toggle_search_box .open_search_box {
    display: none;
  }
  .search_box_state:checked ~ .offcanvas_menu_wrapper .toggle_search_box .close_search_box,
  .search_box_state:target ~ .offcanvas_menu_wrapper .toggle_search_box .close_search_box {
    display: block;
  }
  form[role="search"] {
    display: -webkit-box;
    display: flex;
  }
  form[role="search"] button {
    border-radius: 0 0.625rem 0.625rem 0;
    margin: 0;
    min-width: 3.25rem;
    padding: 0;
    width: 3.25rem;
    background: #0766b1;
    border: 0.0625rem solid #065898;
    color: #fff;
    display: inline-block;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  form[role="search"] button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: calc(3.25rem - (0.0625rem * 2));
    -webkit-box-pack: center;
            justify-content: center;
  }
  form[role="search"] button .icon {
    fill: #fff;
    height: 2rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 2rem;
  }
  form[role="search"] button .text {
    font-weight: 400;
    padding: 0.54666667em 0;
    white-space: nowrap;
  }
  form[role="search"] button .icon + .text {
    padding-left: 0.54666667em;
  }
  form[role="search"] button[disabled] {
    background-color: #eaeaea;
    border-color: #dddddd;
    color: #808080;
  }
  form[role="search"] button[disabled] .icon {
    fill: #000;
  }
  form[role="search"] button:focus {
    box-shadow: 0 0 0 0.1875rem rgba(7, 102, 177, 0.2);
    position: relative;
    z-index: 10;
    background: #0982e2;
    border-color: #065898;
    color: #fff;
  }
  form[role="search"] button:focus .icon {
    fill: #fff;
  }
  form[role="search"] button .icon {
    height: calc(3.25rem - (0.0625rem * 2));
  }
  input[type="search"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    background: #fff;
    border: 0.0625rem solid #d1d1d1;
    border-radius: 0.625rem 0 0 0.625rem;
    box-shadow: 0 0 0.1875rem #d1d1d1 inset;
    color: #000;
    flex-basis: 100%;
    height: 3.25rem;
    margin: 0;
    padding: 0 0.82em;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  input[type="search"]:focus {
    background: #fff;
    border-color: #0766b1;
    box-shadow: 0 0 0.1875rem #0766b1 inset, 0 0 0 0.1875rem rgba(7, 102, 177, 0.2);
    color: #000;
    outline: 0;
    position: relative;
    z-index: 10;
  }
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
  .search dl {
    margin: 0 0 0.82em;
    padding-top: 0.41em;
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .search dd {
    margin: 0;
  }
  .social_media_links {
    align-self: center;
    margin-left: 0.82em;
  }
  .social_media_links ul {
    display: -webkit-box;
    display: flex;
    margin: 0;
    padding: 0;
  }
  .social_media_links li {
    align-self: center;
    display: -webkit-box;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0 0.328em 0 0;
  }
  .social_media_links a {
    border-radius: 0.625rem;
    display: inline-block;
  }
  .social_media_links a .icon {
    align-self: center;
    display: -webkit-box;
    display: flex;
    fill: #fff;
    height: 2.375rem;
    width: 2.375rem;
  }
  .social_media_links a.facebook {
    margin-top: -0.1875rem;
  }
  .social_media_links a.facebook .icon {
    padding: 0.1875rem;
  }
  .social_media_links a.video svg {
    padding: 0.375rem;
  }
  .social_media_share ul {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 1.968em 0 0.82em 0;
    padding: 0;
  }
  .social_media_share li {
    list-style: none;
    margin: 0.41em 0;
    padding: 0;
  }
  .social_media_share a {
    color: #000;
    cursor: pointer;
    display: block;
    margin-right: 0.82em;
    padding-right: 0.82em;
    text-decoration: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .social_media_share a > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 3.25rem;
  }
  .social_media_share a .icon {
    border-radius: 0.625rem;
    height: 2.125rem;
    width: 2.125rem;
  }
  .social_media_share a .icon svg {
    fill: #fff;
    height: 2.125rem;
    width: 2.125rem;
  }
  .social_media_share a .text {
    font-weight: 700;
    margin-left: 0.82em;
    position: relative;
  }
  .social_media_share a .text::after {
    background-color: #000;
    bottom: 0.125rem;
    content: "";
    height: 0.0625rem;
    left: 0;
    opacity: 1;
    position: absolute;
    right: 0;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  .social_media_share a:focus .text::after,
  .social_media_share a:hover .text::after {
    left: 50%;
    opacity: 0;
    right: 50%;
  }
  .social_media_share a.facebook .icon {
    background: #3664a2;
  }
  .social_media_share a.twitter .icon {
    background: #1da1f2;
  }
  .social_media_share a.print .icon {
    background: #0766b1;
    text-align: center;
  }
  .social_media_share a.print .icon svg {
    height: 2.125rem;
    width: 1.5rem;
  }
  .social_media_legal_notice {
    padding: 1.64em;
  }
  .social_media_legal_notice .company_logo {
    margin: 0 auto;
    text-align: center;
  }
  .steuernews_article_slider {
    display: none;
  }
  .steuernews_content [href*="thema"] {
    color: #000;
  }
  .steuernews_preview {
    align-content: flex-start;
    -webkit-box-align: start;
            align-items: flex-start;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
            justify-content: space-between;
    margin: 0.82em 0 0 0;
  }
  .steuernews_preview h2 {
    font-weight: 400;
    width: 100%;
  }
  .steuernews_preview > .title {
    width: 100%;
  }
  .steuernews_preview .entry {
    align-content: flex-start;
    -webkit-box-align: stretch;
            align-items: stretch;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
            justify-content: flex-start;
    margin-bottom: 2.46em;
    position: relative;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    width: 100%;
  }
  .steuernews_preview .entry .image {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    margin: 0;
    max-width: 26.5625rem;
    -webkit-box-ordinal-group: 2;
            order: 1;
    overflow: hidden;
    padding-bottom: 0.82em;
    width: 100%;
  }
  .steuernews_preview .entry .image img {
    border: 0.0625rem solid #e2e2e2;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    width: 100%;
  }
  .steuernews_preview .entry .image .video_play {
    position: relative;
  }
  .steuernews_preview .entry .image .video_play img {
    border: 0.0625rem solid #e2e2e2;
  }
  .steuernews_preview .entry .image .video_play .icon {
    fill: #0766b1;
    height: 3rem;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 3rem;
  }
  .steuernews_preview .entry .teaser_text {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .steuernews_preview .entry .title {
    color: #000;
    margin-bottom: 0.82em;
    position: relative;
  }
  .steuernews_preview .entry .title::after {
    background-color: #0766b1;
    bottom: -0.41em;
    content: "";
    height: 0.0625rem;
    left: 0;
    opacity: 1;
    position: absolute;
    right: 30%;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  .steuernews_preview .entry p {
    margin-bottom: 0;
  }
  .steuernews_preview .entry:focus::after,
  .steuernews_preview .entry:hover::after {
    opacity: 1;
  }
  .steuernews_preview .entry:focus .title,
  .steuernews_preview .entry:hover .title {
    color: #000;
  }
  .steuernews_preview .entry:focus .title::after,
  .steuernews_preview .entry:hover .title::after {
    right: 80%;
  }
  .steuernews_preview .entry:focus .image img,
  .steuernews_preview .entry:hover .image img {
    -webkit-transform: scale(1.04);
            transform: scale(1.04);
  }
  .ie_eleven .steuernews_preview .entry .image {
    height: 100%;
  }
  .steuernews_search {
    margin: 0 0 1.64em;
  }
  .steuernews_search .search {
    margin: 0 0 0.82em;
    max-width: 23.75rem;
  }
  .news_entity .creation_date {
    display: none;
  }
  .news_image {
    margin-bottom: 0.82em;
    width: 100%;
  }
  .news_image + * {
    padding-top: 0;
  }
  .steuernews_current_issue {
    border-top: 0.0625rem solid #eaeaea;
    margin: 1.64em 0;
    padding: 0.82em 0 0 0;
  }
  .steuernews_current_issue h2 {
    font-weight: 400;
  }
  .steuernews_current_issue ul {
    list-style: none;
    padding-left: 0;
  }
  .steuernews_current_issue ul li {
    padding-left: 0;
  }
  .steuernews_current_issue ul li a {
    color: #000;
    padding-left: 1.312em;
    position: relative;
    text-decoration: none;
  }
  .steuernews_current_issue ul li a::after {
    border-color: transparent transparent transparent #eaeaea;
    border-style: solid;
    border-width: 0.4375rem 0 0.4375rem 0.5rem;
    content: "";
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  .steuernews_current_issue ul li a.active::after {
    border-color: transparent transparent transparent #0766b1;
  }
  .steuernews_current_issue ul li a:focus,
  .steuernews_current_issue ul li a:hover {
    text-decoration: underline;
  }
  .steuernews_current_issue ul li a:focus::after,
  .steuernews_current_issue ul li a:hover::after {
    border-color: transparent transparent transparent #0766b1;
  }
  .steuernews_navigation,
  .steuernews_issues,
  .steuernews_keywords {
    border-top: 0.0625rem solid #eaeaea;
    margin: 1.64em 0;
    padding: 0.82em 0;
  }
  .steuernews_navigation h2,
  .steuernews_issues h2,
  .steuernews_keywords h2 {
    font-weight: 400;
  }
  .steuernews_navigation ul,
  .steuernews_issues ul,
  .steuernews_keywords ul {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .steuernews_navigation a,
  .steuernews_issues a,
  .steuernews_keywords a {
    margin: 0 0.54666667em 0.54666667em 0;
    background: #fff;
    border: 0.0625rem solid #0766b1;
    color: #000;
    display: inline-block;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .steuernews_navigation a > span,
  .steuernews_issues a > span,
  .steuernews_keywords a > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: calc(3.25rem - (0.0625rem * 2));
    -webkit-box-pack: center;
            justify-content: center;
  }
  .steuernews_navigation a .icon,
  .steuernews_issues a .icon,
  .steuernews_keywords a .icon {
    fill: #000;
    height: 2rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 2rem;
  }
  .steuernews_navigation a .text,
  .steuernews_issues a .text,
  .steuernews_keywords a .text {
    font-weight: 400;
    padding: 0.54666667em 0;
    white-space: nowrap;
  }
  .steuernews_navigation a .icon + .text,
  .steuernews_issues a .icon + .text,
  .steuernews_keywords a .icon + .text {
    padding-left: 0.54666667em;
  }
  .steuernews_navigation a .icon,
  .steuernews_issues a .icon,
  .steuernews_keywords a .icon {
    fill: #0766b1;
  }
  .steuernews_navigation a:focus,
  .steuernews_issues a:focus,
  .steuernews_keywords a:focus,
  .steuernews_navigation a:hover,
  .steuernews_issues a:hover,
  .steuernews_keywords a:hover {
    background: #0766b1;
    border-color: #0766b1;
    color: #fff;
  }
  .steuernews_navigation a:focus .icon,
  .steuernews_issues a:focus .icon,
  .steuernews_keywords a:focus .icon,
  .steuernews_navigation a:hover .icon,
  .steuernews_issues a:hover .icon,
  .steuernews_keywords a:hover .icon {
    fill: #fff;
  }
  .steuernews_navigation a.active,
  .steuernews_issues a.active,
  .steuernews_keywords a.active {
    color: #fff;
  }
  .steuernews_navigation a:focus,
  .steuernews_issues a:focus,
  .steuernews_keywords a:focus,
  .steuernews_navigation a:hover,
  .steuernews_issues a:hover,
  .steuernews_keywords a:hover {
    text-decoration: none;
  }
  .steuernews_issues {
    border-bottom: 0.0625rem solid #d1d1d1;
    padding-bottom: 1.64em;
  }
  .steuernews_issues li {
    display: none;
  }
  .steuernews_issues li.show {
    display: block;
  }
  .steuernews_issues li.show_always {
    display: block;
    margin-left: auto;
  }
  .video_steuernews_tv_overview .steuernews_issues,
  .video_steuernews_tv_overview .steuernews_keywords {
    border-bottom: 0;
  }
  .steuernews_video_overview {
    margin-bottom: 1.64em;
  }
  .steuernews_video_overview .entry {
    color: #000;
    display: inline-block;
    font-size: 120%;
    margin-bottom: 0.984em;
    text-decoration: none;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  .steuernews_video_overview .entry .image {
    margin: 0;
    position: relative;
  }
  .steuernews_video_overview .entry .video_play {
    position: relative;
  }
  .steuernews_video_overview .entry .video_play .icon {
    fill: #0766b1;
    height: 3.125rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  .steuernews_video_overview .entry figcaption {
    font-weight: 400;
  }
  .steuernews_video_overview .entry:focus,
  .steuernews_video_overview .entry:hover {
    background-color: #ffffff;
    box-shadow: 0 0 0 0.75rem #ffffff;
  }
  .steuernews_video_overview .entry:focus .video_play .icon,
  .steuernews_video_overview .entry:hover .video_play .icon {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  .steuernews_video_overview .more_steuernews_tv .entry {
    margin-bottom: 1.64em;
    width: 100%;
  }
  .steuernews_video_overview .more_steuernews_tv .entry figcaption {
    margin-top: 0.82em;
  }
  .steuernews_video_overview .more_steuernews_tv .entry img {
    border: 0.25rem solid #fff;
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.35);
    width: 100%;
  }
  .steuernews_newsletter_subscribe {
    margin-top: 1.64em;
    padding: 1.64em 0;
    position: relative;
    z-index: 1;
  }
  .steuernews_newsletter_subscribe::after {
    background-color: #eaeaea;
    bottom: 0;
    content: "";
    left: -624.9375rem;
    position: absolute;
    right: -624.9375rem;
    top: 0;
    z-index: -1;
  }
  .steuernews_newsletter_subscribe .select,
  .steuernews_newsletter_subscribe input,
  .steuernews_newsletter_subscribe .control .indicator,
  .steuernews_newsletter_subscribe .form_disclaimer {
    background-color: #fff;
  }
  .steuernews_sidebar {
    margin-top: 0;
  }
  .steuernews_sidebar .video_preview {
    border-top: 0.0625rem solid #eaeaea;
    margin: 1.64em 0;
    padding: 1.64em 0 0.82em 0;
  }
  .steuernews_sidebar .video_preview .overview_title {
    width: 100%;
  }
  .steuernews_sidebar .video_preview .touch_link {
    -webkit-box-align: start;
            align-items: flex-start;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin: 1.64em 0;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  .steuernews_sidebar .video_preview .touch_link .title {
    font-weight: 400;
  }
  .steuernews_sidebar .video_preview .touch_link .sub_title {
    color: #000;
    display: block;
  }
  .steuernews_sidebar .video_preview .touch_link .image {
    display: -webkit-inline-box;
    display: inline-flex;
    margin-bottom: 0.82em;
    padding-right: 0;
  }
  .steuernews_sidebar .video_preview .touch_link:focus,
  .steuernews_sidebar .video_preview .touch_link:hover {
    background-color: #ffffff;
    box-shadow: 0 0 0 0.75rem #ffffff;
  }
  .steuernews_call_to_action {
    background: #fff;
    border-radius: 0.5rem;
    box-shadow: inset 0 0 0.75rem rgba(0, 0, 0, 0.2);
    color: #000;
    margin: 2.46em 0;
    padding: 1.09333333em 1.64em;
    text-align: center;
  }
  .steuernews_call_to_action p {
    margin-bottom: 0;
  }
  .steuernews_keywords_register_index a {
    margin: 0 0.54666667em 0.54666667em 0;
    padding: 0;
    width: 3.25rem;
    background: #fff;
    border: 0.0625rem solid #0766b1;
    color: #000;
    display: inline-block;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .steuernews_keywords_register_index a > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: calc(3.25rem - (0.0625rem * 2));
    -webkit-box-pack: center;
            justify-content: center;
  }
  .steuernews_keywords_register_index a .icon {
    fill: #000;
    height: 2rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 2rem;
  }
  .steuernews_keywords_register_index a .text {
    font-weight: 400;
    padding: 0.54666667em 0;
    white-space: nowrap;
  }
  .steuernews_keywords_register_index a .icon + .text {
    padding-left: 0.54666667em;
  }
  .steuernews_keywords_register_index a .icon {
    fill: #0766b1;
  }
  .steuernews_keywords_register_index a:focus,
  .steuernews_keywords_register_index a:hover {
    background: #0766b1;
    border-color: #0766b1;
    color: #fff;
  }
  .steuernews_keywords_register_index a:focus .icon,
  .steuernews_keywords_register_index a:hover .icon {
    fill: #fff;
  }
  .steuernews_keywords_register_index a.active {
    color: #fff;
  }
  .steuernews_keywords_register_index a:focus,
  .steuernews_keywords_register_index a:hover {
    text-decoration: none;
  }
  .highlight_keyword {
    background-color: #0766b1;
    color: #fff;
  }
  .team_overview .entry {
    align-content: center;
    -webkit-box-align: center;
            align-items: center;
    border-bottom: 0.0625rem solid #000;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
            justify-content: flex-start;
    margin: 0 auto 3.28em auto;
    max-width: 29.6875rem;
  }
  .team_overview .entry:last-child {
    border-bottom: 0;
  }
  .team_overview .title {
    margin: 0;
    padding: 0;
  }
  .team_overview .title a {
    text-decoration: none;
  }
  .team_overview .title a .inner_wrapper {
    color: #0766b1;
    position: relative;
  }
  .team_overview .title a .inner_wrapper::after {
    background-color: #0766b1;
    bottom: -0.164em;
    content: "";
    height: 0.1875rem;
    left: 0;
    opacity: 1;
    position: absolute;
    right: 30%;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  .team_overview .title a:hover,
  .team_overview .title a:focus {
    color: #0766b1;
  }
  .team_overview .title a:hover .inner_wrapper,
  .team_overview .title a:focus .inner_wrapper {
    color: #0766b1;
  }
  .team_overview .title a:hover .inner_wrapper::after,
  .team_overview .title a:focus .inner_wrapper::after {
    background-color: #0766b1;
    right: 60%;
  }
  .team_overview .photo {
    margin: 1.64em 0 2.46em 0;
    overflow: hidden;
    width: 100%;
  }
  .team_overview .photo img {
    border: 0.0625rem solid #e2e2e2;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  .team_overview .info {
    align-content: center;
    -webkit-box-align: start;
            align-items: flex-start;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
            justify-content: flex-start;
    margin-bottom: 0.82em;
    padding: 0 3.28em;
    width: 100%;
  }
  .team_overview .info .contact strong {
    color: #0766b1;
    margin-right: 0.492em;
    min-width: 0.625rem;
  }
  .team_overview .info .contact a {
    color: #000;
  }
  .team_overview .info .button {
    align-self: center;
    margin: 1.64em 0 0 0;
  }
  .team_overview .info p {
    width: 100%;
  }
  .team_entry .photo {
    margin-bottom: 3.28em;
    max-width: 31.25rem;
    position: relative;
    z-index: 0;
  }
  .team_entry .photo::after {
    background-color: #eaeaea;
    bottom: 0;
    content: "";
    left: -624.9375rem;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
  .team_entry .photo img {
    border: 0.0625rem solid #e2e2e2;
    display: block;
  }
  .team_entry .info {
    margin-bottom: 0.82em;
  }
  .team_entry .info p {
    width: 100%;
  }
  .team_entry .buttons {
    margin-bottom: 3.28em;
  }
  .team_entry .buttons .button {
    align-self: center;
  }
  .team_group_overview {
    border-bottom: 0.0625rem solid #e2e2e2;
  }
  .tooltip_wrapper {
    display: block;
    position: relative;
  }
  [role="tooltip"] {
    background: #eaeaea;
    border: 0.0625rem solid #d1d1d1;
    border-radius: 0.625rem;
    color: #000;
    font-size: 85%;
    font-weight: 400;
    line-height: 2.0625rem;
    padding: 0 0.82em;
    position: absolute;
    right: 0;
    text-align: center;
    top: -2.4375rem;
    z-index: 50;
  }
  [role="tooltip"]:after {
    border: 0.625rem solid transparent;
    border-top-color: #eaeaea;
    bottom: -1.25rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.625rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"]:before {
    border: 0.6875rem solid transparent;
    border-top-color: #d1d1d1;
    bottom: -1.375rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.6875rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"][aria-hidden="true"] {
    display: none;
  }
  .video_preview .touch_link,
  .video_infolists_overview .touch_link,
  .video_overview .touch_link {
    display: inline-block;
    text-decoration: none;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  .video_preview .touch_link .image,
  .video_infolists_overview .touch_link .image,
  .video_overview .touch_link .image {
    -webkit-box-align: start;
            align-items: flex-start;
    margin: 0.82em 0;
  }
  .video_preview .touch_link .image img,
  .video_infolists_overview .touch_link .image img,
  .video_overview .touch_link .image img {
    border: 0.0625rem solid #e2e2e2;
    width: 100%;
  }
  .video_preview .touch_link .image figcaption,
  .video_infolists_overview .touch_link .image figcaption,
  .video_overview .touch_link .image figcaption {
    font-size: 100%;
    margin: 0.82em 0 0 0;
  }
  .video_preview .touch_link .video_play,
  .video_infolists_overview .touch_link .video_play,
  .video_overview .touch_link .video_play {
    position: relative;
  }
  .video_preview .touch_link .video_play .icon,
  .video_infolists_overview .touch_link .video_play .icon,
  .video_overview .touch_link .video_play .icon {
    fill: #0766b1;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    -webkit-transition: -webkit-transform 300ms ease-in-out;
    transition: -webkit-transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
  }
  .video_preview .touch_link:focus .video_play .icon,
  .video_infolists_overview .touch_link:focus .video_play .icon,
  .video_overview .touch_link:focus .video_play .icon,
  .video_preview .touch_link:hover .video_play .icon,
  .video_infolists_overview .touch_link:hover .video_play .icon,
  .video_overview .touch_link:hover .video_play .icon {
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
  }
  .video_wrapper {
    border: 0.0625rem solid #e2e2e2;
    height: 0;
    margin-bottom: 1.64em;
    padding-top: 56.25%;
    position: relative;
  }
  .video_wrapper video,
  .video_wrapper iframe {
    border: 0.0625rem solid #e2e2e2;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .video_wrapper video::cue {
    background: #000000;
    color: #ffffff;
  }
  .video_preview {
    margin-bottom: 0.82em;
  }
  .video_preview .touch_link {
    border-bottom: 0.0625rem dotted #000;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin: 0 0 1.64em 0;
    padding: 0 0 0.656em 0;
  }
  .video_preview .touch_link .image {
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    margin: 0 0 0.82em 0;
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .video_preview .touch_link .teaser_text {
    -webkit-box-ordinal-group: 3;
            order: 2;
    width: 100%;
  }
  .video_infolist .touch_link img {
    max-width: 20.125rem;
  }
  html {
    height: 100%;
  }
  .main_wrapper {
    -webkit-box-align: stretch;
            align-items: stretch;
    align-self: stretch;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 0;
    -webkit-box-pack: justify;
            justify-content: space-between;
    margin: 0;
    min-height: 25rem;
    overflow-x: hidden;
    padding: 3.772em 0 2.952em 0;
  }
  .container {
    width: 100%;
  }
  .page_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    height: 100%;
    -webkit-box-pack: justify;
            justify-content: space-between;
    margin: 0 auto;
    max-width: 160rem;
    min-height: 100vh;
    min-width: 20rem;
    overflow-x: hidden;
    width: 100%;
  }
  .startpage .page_wrapper {
    background-image: none;
  }
  .sitemap h2 a {
    text-decoration: none;
  }
  .sitemap ul li a {
    color: #000;
  }
  a.touch_link .title {
    text-decoration: none;
  }
  .sitemap,
  .column_two_all,
  .column_two ul,
  .column_two p {
    -webkit-column-gap: 0.9375rem;
            column-gap: 0.9375rem;
  }
  .sitemap li,
  .column_two_all li,
  .column_two ul li,
  .column_two p li {
    -webkit-column-break-inside: avoid;
            break-inside: avoid-column;
  }
  .sitemap h1,
  .column_two_all h1,
  .column_two ul h1,
  .column_two p h1,
  .sitemap h2,
  .column_two_all h2,
  .column_two ul h2,
  .column_two p h2,
  .sitemap h3,
  .column_two_all h3,
  .column_two ul h3,
  .column_two p h3,
  .sitemap h4,
  .column_two_all h4,
  .column_two ul h4,
  .column_two p h4 {
    -webkit-column-fill: balance;
            column-fill: balance;
    -webkit-column-span: all;
            column-span: all;
  }
  .sitemap h1,
  .column_two_all h1,
  .column_two ul h1,
  .column_two p h1,
  .sitemap h2,
  .column_two_all h2,
  .column_two ul h2,
  .column_two p h2,
  .sitemap h3,
  .column_two_all h3,
  .column_two ul h3,
  .column_two p h3,
  .sitemap h4,
  .column_two_all h4,
  .column_two ul h4,
  .column_two p h4,
  .sitemap p,
  .column_two_all p,
  .column_two ul p,
  .column_two p p {
    -webkit-column-break-inside: auto;
            break-inside: auto;
  }
}
@media screen and (min-width: 20rem) {
  .container {
    padding: 0 calc(0.9375rem + 2.1875
                                     * (100vw - 20rem)
                                     / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125em) {
  .container {
    padding: 0 3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row {
    margin: 0 calc((-0.9375rem / 2) - (2.1875 / 2)
                                     * (100vw - 20rem)
                                     / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125em) {
  .row {
    margin: 0 -1.5625rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin {
    margin: 0 calc((-0.9375rem / 4) - (2.1875 / 4)
                                     * (100vw - 20rem)
                                     / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125em) {
  .row.half_column_margin {
    margin: 0 -0.78125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 calc((0.9375rem / 4) + (2.1875 / 4)
                                     * (100vw - 20rem)
                                     / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125em) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 0.78125rem;
  }
}
@media screen and (min-width: 30em) {
  .row.around_small {
    justify-content: space-around;
  }
  .row.between_small {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_small {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_small {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_small {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_small {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_small {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_small {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_small {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 35em) {
  .row.around_medium {
    justify-content: space-around;
  }
  .row.between_medium {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_medium {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_medium {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_medium {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_medium {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_medium {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_medium {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_medium {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 47.5em) {
  .row.around_large {
    justify-content: space-around;
  }
  .row.between_large {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_large {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_large {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_large {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_large {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_large {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_large {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_large {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 61.875em) {
  .row.around_xlarge {
    justify-content: space-around;
  }
  .row.between_xlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 68.75em) {
  .row.around_xxlarge {
    justify-content: space-around;
  }
  .row.between_xxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 78.125em) {
  .row.around_xxxlarge {
    justify-content: space-around;
  }
  .row.between_xxxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 20rem) {
  [class*="span"] {
    padding: 0 calc((0.9375rem / 2) + (2.1875 / 2)
                                     * (100vw - 20rem)
                                     / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125em) {
  [class*="span"] {
    padding: 0 1.5625rem;
  }
}
@media screen and (min-width: 30em) {
  .span1_small {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_small {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_small {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_small {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_small {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_small {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_small {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_small {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_small {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_small {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_small {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_small {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_small {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_small {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_small {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_small {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_small {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_small {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_small {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_small {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_small {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_small {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_small {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_small {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 35em) {
  .span1_medium {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_medium {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_medium {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_medium {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_medium {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_medium {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_medium {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_medium {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_medium {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_medium {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_medium {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_medium {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_medium {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_medium {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_medium {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_medium {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_medium {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_medium {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_medium {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_medium {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_medium {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_medium {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_medium {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_medium {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 47.5em) {
  .span1_large {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_large {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_large {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_large {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_large {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_large {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_large {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_large {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_large {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_large {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_large {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_large {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_large {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_large {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_large {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_large {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_large {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_large {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_large {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_large {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_large {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_large {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_large {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_large {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 61.875em) {
  .span1_xlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 68.75em) {
  .span1_xxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 78.125em) {
  .span1_xxxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (max-width: 34.9375em) {
  header .one {
    position: absolute;
    z-index: 44;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}
@media screen and (max-width: 34.9375em) {
  header .one .container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-align: center;
            align-items: center;
    padding: 0;
    min-width: 16.25rem;
  }
}
@media screen and (min-width: 20rem) {
  header .one .container .company_logo {
    max-width: calc(16.25rem + 1.25
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125em) {
  header .one .container .company_logo {
    max-width: 17.5rem;
  }
}
@media screen and (max-width: 34.9375em) {
  header .one .container .company_logo {
    margin-right: 0;
  }
}
@media screen and (min-width: 68.75em) {
  header .one.scrolled_down {
    position: fixed;
    z-index: 2;
    width: 100%;
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.15);
  }
  header .one.scrolled_down .container {
    display: -webkit-box;
    display: flex;
  }
  header .one.scrolled_down .container .company_logo {
    max-width: 13.75rem;
  }
}
@media screen and (min-width: 68.75em) and (min-width: 20rem) {
  header .one.scrolled_down .container .company_logo {
    max-width: calc(13.75rem + 0
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 68.75em) and (min-width: 78.125em) {
  header .one.scrolled_down .container .company_logo {
    max-width: 13.75rem;
  }
}
@media screen and (min-width: 35em) {
  header .one .logo_etl {
    margin-left: 0.41em;
    margin-right: -0.82em;
    width: auto;
  }
}
@media screen and (min-width: 47.5em) {
  header .one .logo_etl {
    margin-left: 0.82em;
    margin-right: -2.378em;
  }
}
@media screen and (min-width: 35em) {
  header .one .logo_etl a img {
    max-width: 6.5625rem;
  }
}
@media screen and (min-width: 47.5em) {
  header .one .logo_etl a img {
    max-width: 7.8125rem;
  }
}
@media screen and (max-width: 34.9375em) {
  header .two .slider .slogan {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@media screen and (min-width: 35em) {
  header .two .slider .slogan {
    top: 30%;
  }
}
@media screen and (min-width: 68.75em) {
  header .two .slider .slogan {
    top: 40%;
  }
}
@media screen and (min-width: 20rem) {
  header .two .slider .slogan h2 {
    font-size: calc(1.125rem + 1.5
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125em) {
  header .two .slider .slogan h2 {
    font-size: 2.625rem;
  }
}
@media screen and (max-width: 47.4375em) {
  header .two .slider .slogan h2 {
    padding: 0.082em 0;
  }
}
@media screen and (max-width: 47.4375em) {
  header .two .slider .slogan h2::after {
    right: 0;
  }
}
@media screen and (min-width: 20rem) {
  header .two .slider .slogan h3 {
    font-size: calc(0.875rem + 0.5
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125em) {
  header .two .slider .slogan h3 {
    font-size: 1.375rem;
  }
}
@media screen and (max-width: 47.4375em) {
  header .two .slider .slogan h3 {
    padding: 0.82em 1.312em 0.82em 0;
  }
  header .two .slider .slogan h3 br {
    display: none;
  }
}
@media screen and (min-width: 68.75em) {
  header .two .slider .slogan h3::after {
    left: 0;
  }
}
@media screen and (max-width: 29.9375em) {
  .address_vcard_button {
    width: 100%;
  }
}
@media screen and (max-width: 29.9375em) {
  .address_call_button {
    width: 100%;
  }
}
@media screen and (min-width: 47.5em) {
  .useful_link_overview .touch_link {
    margin-bottom: 0;
    width: 48%;
  }
}
@media screen and (min-width: 20rem) {
  .startpage .button_special {
    font-size: calc(1.125rem + 0.1875
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125em) {
  .startpage .button_special {
    font-size: 1.3125rem;
  }
}
@media screen and (min-width: 20rem) {
  .steuernews_article_overview_startpage .button_imitation,
  .teaser .button_imitation {
    font-size: calc(1.125rem + 0.1875
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125em) {
  .steuernews_article_overview_startpage .button_imitation,
  .teaser .button_imitation {
    font-size: 1.3125rem;
  }
}
@media screen and (min-width: 61.875em) {
  .teaer_special_startpage {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 61.875em) {
  .teaer_special_startpage .teaser {
    padding: 0;
    height: 30rem;
    width: 50%;
  }
}
@media screen and (min-width: 61.875em) {
  .teaer_special_startpage .teaser .button {
    margin-top: 0;
  }
}
@media screen and (max-width: 61.8125em) {
  .teaer_special_startpage .teaser .description {
    display: none;
  }
}
@media screen and (min-width: 47.5em) {
  .teaer_special_startpage .teaser .description .title,
  .teaer_special_startpage .teaser .description p {
    text-align: left;
  }
}
@media screen and (min-width: 61.875em) {
  .teaer_special_startpage .teaser:nth-child(1) {
    padding-right: 4.92em;
  }
  .teaer_special_startpage .teaser:nth-child(1)::after {
    right: 0;
  }
}
@media screen and (min-width: 61.875em) {
  .teaer_special_startpage .teaser:nth-child(2) {
    padding-left: 4.92em;
  }
  .teaer_special_startpage .teaser:nth-child(2)::after {
    left: 0;
  }
}
@media screen and (min-width: 20rem) {
  .teaer_special_startpage .teaser .icon {
    width: calc(6.25rem + 3.75
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125em) {
  .teaer_special_startpage .teaser .icon {
    width: 10rem;
  }
}
@media screen and (min-width: 20rem) {
  .teaer_special_startpage .teaser .icon {
    height: calc(6.25rem + 3.75
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125em) {
  .teaer_special_startpage .teaser .icon {
    height: 10rem;
  }
}
@media screen and (min-width: 87.5rem) {
  aside .aside_leistungs.newsletter_anmeldung::before,
  aside .teaser.newsletter_anmeldung::before,
  aside .aside_leistungs::after,
  aside .teaser::after {
    right: calc((100vw - 1300px) / -2);
  }
}
@media screen and (min-width: 20rem) {
  aside .aside_leistungs .icon,
  aside .teaser .icon {
    height: calc(4.375rem + 1.875
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125em) {
  aside .aside_leistungs .icon,
  aside .teaser .icon {
    height: 6.25rem;
  }
}
@media screen and (min-width: 20rem) {
  aside .aside_leistungs .icon,
  aside .teaser .icon {
    width: calc(4.375rem + 1.875
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125em) {
  aside .aside_leistungs .icon,
  aside .teaser .icon {
    width: 6.25rem;
  }
}
@media screen and (min-width: 20rem) {
  aside .aside_leistungs a {
    font-size: calc(1.1875rem + 0.1875
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125em) {
  aside .aside_leistungs a {
    font-size: 1.375rem;
  }
}
@media screen and (min-width: 47.5em) {
  footer .one .bottom .steuernews_article_overview_startpage::before,
  footer .one .bottom .teaser::before {
    display: none;
  }
}
@media screen and (min-width: 47.5em) {
  footer .one .bottom .steuernews_article_overview_startpage,
  footer .one .bottom .teaser {
    width: 48%;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 68.75em) {
  footer .one .bottom .steuernews_article_overview_startpage,
  footer .one .bottom .teaser {
    width: 30%;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 20rem) {
  footer .one .bottom .steuernews_article_overview_startpage .icon,
  footer .one .bottom .teaser .icon {
    height: calc(3.75rem + 3.125
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125em) {
  footer .one .bottom .steuernews_article_overview_startpage .icon,
  footer .one .bottom .teaser .icon {
    height: 6.875rem;
  }
}
@media screen and (min-width: 20rem) {
  footer .one .bottom .steuernews_article_overview_startpage .icon,
  footer .one .bottom .teaser .icon {
    margin-bottom: calc(0.9839999999999999em + 0.9839999999999999
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125em) {
  footer .one .bottom .steuernews_article_overview_startpage .icon,
  footer .one .bottom .teaser .icon {
    margin-bottom: 1.968em;
  }
}
@media screen and (min-width: 20rem) {
  footer .one .bottom .steuernews_article_overview_startpage .icon,
  footer .one .bottom .teaser .icon {
    width: calc(3.75rem + 3.125
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125em) {
  footer .one .bottom .steuernews_article_overview_startpage .icon,
  footer .one .bottom .teaser .icon {
    width: 6.875rem;
  }
}
@media screen and (min-width: 68.75em) {
  footer .one .bottom .steuernews_article_overview_startpage {
    display: -webkit-box;
    display: flex;
  }
}
@media screen and (min-width: 68.75em) {
  footer .two .container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 68.75em) {
  footer .two .container > .address {
    width: 48%;
  }
}
@media screen and (min-width: 20rem) {
  footer .two .container p {
    font-size: calc(0.9375rem + 0.125
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125em) {
  footer .two .container p {
    font-size: 1.0625rem;
  }
}
@media screen and (min-width: 20rem) {
  footer .two .container .company_name {
    font-size: calc(1rem + 0.1875
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125em) {
  footer .two .container .company_name {
    font-size: 1.1875rem;
  }
}
@media screen and (min-width: 20rem) {
  footer .three .related_links ul li a {
    font-size: calc(0.9375rem + 0.0625
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125em) {
  footer .three .related_links ul li a {
    font-size: 1rem;
  }
}
@media screen and (min-width: 47.5em) {
  .startpage .one .bottom {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 78.125em) {
  .dropdown_menu {
    display: -webkit-box;
    display: flex;
    z-index: 1;
  }
}
@media screen and (min-width: 20rem) {
  .dropdown_menu > ul > li > a {
    font-size: calc(1.125rem + 0.1875
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125em) {
  .dropdown_menu > ul > li > a {
    font-size: 1.3125rem;
  }
}
@media screen and (min-width: 20rem) {
  .dropdown_menu > ul > li ul a {
    font-size: calc(1rem + 0.125
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125em) {
  .dropdown_menu > ul > li ul a {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 35em) {
  .google_maps .step:before {
    font-size: inherit;
  }
}
@media screen and (min-width: 35em) {
  .google_maps .instructions,
  .google_maps .distance {
    font-size: inherit;
  }
}
@media screen and (max-width: 61.8125em) {
  .u_online_overview {
    margin: 0;
  }
}
@media screen and (max-width: 61.8125em) {
  .u_online_overview .span12_xsmall {
    margin: 1.312em 0.82em;
  }
}
@media screen and (min-width: 47.5em) {
  .img_container.u_online_svg_2_small {
    display: none;
  }
}
@media screen and (min-width: 47.5em) {
  .img_container.u_online_svg_2 {
    display: block;
  }
}
@media screen and (min-width: 47.5em) {
  .u_online_2_headline {
    font-size: 80%;
  }
}
@media screen and (min-width: 47.5em) {
  .u_online_text {
    font-size: 80%;
  }
}
@media screen and (min-width: 61.875em) {
  .u_online_benefits_flow .table {
    display: table;
    margin: 0 auto;
    padding: 0;
  }
  .u_online_benefits_flow .table_cell {
    display: table-cell;
    padding: 0 1.64em;
    vertical-align: middle;
  }
  .u_online_benefits_flow .table_cell.text {
    max-width: 31.25rem;
  }
  .u_online_benefits_flow .flow_text {
    display: block;
  }
  .u_online_benefits_flow .header {
    display: block;
    text-align: center;
  }
  .u_online_benefits_flow .header .text {
    display: block;
    margin: 0 auto 0.82em;
    padding: 0;
    text-align: center;
    width: 80%;
  }
  .u_online_benefits_flow .header:after {
    background: url("../images/u_online/u_online_arrow.svg") no-repeat;
    display: block;
    height: 0;
    margin: 0 auto;
    padding-bottom: 16.15853659%;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    width: auto;
  }
  .u_online_benefits_flow .footer {
    display: block;
    text-align: center;
  }
  .u_online_benefits_flow .footer .text {
    display: block;
    margin: 0.82em auto 0;
    padding: 0;
    text-align: center;
    width: 80%;
  }
  .u_online_benefits_flow .footer:before {
    background: url("../images/u_online/u_online_arrow.svg") no-repeat;
    content: "";
    display: block;
    height: 0;
    margin: 0 auto 0.8em;
    padding-bottom: 16.15853659%;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    width: auto;
  }
  .u_online_benefits_flow .description {
    max-height: none;
    overflow: none;
    padding: 0 0.8em;
  }
  .u_online_benefits_flow .lock {
    margin-bottom: 1.64em;
  }
  .u_online_benefits_flow .lock:before {
    height: 3.5rem;
    width: 3.5rem;
  }
  .u_online_benefits_flow .benefits_button {
    display: none;
  }
}
@media screen and (max-width: 34.9375em) {
  .toggle_offcanvas_menu {
    padding: 0.246em 0;
  }
}
@media screen and (min-width: 78.125em) {
  .toggle_offcanvas_menu {
    display: none;
  }
}
@media screen and (min-width: 78.125em) {
  .offcanvas_menu_wrapper {
    overflow-x: inherit;
  }
}
@media screen and (max-width: 78.0625em) {
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu,
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .page,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .page {
    opacity: 1;
    -webkit-transform: translate(16.25rem, 0);
            transform: translate(16.25rem, 0);
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu_content,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu_content {
    opacity: 1;
    -webkit-transition: none;
    transition: none;
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay {
    opacity: 1;
    visibility: visible;
  }
}
@media screen and (max-width: 78.0625em) and (min-width: 30em) {
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu,
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .page,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .page {
    -webkit-transform: translate(20rem, 0);
            transform: translate(20rem, 0);
  }
}
@media screen and (min-width: 30em) {
  .offcanvas_menu {
    -webkit-box-flex: 1;
            flex: 1 0 20rem;
  }
}
@media screen and (min-width: 20rem) {
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    font-size: calc(1.2rem + 0.1499999999999999
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125em) {
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    font-size: 1.35rem;
  }
}
@media screen and (max-width: 34.9375em) {
  .toggle_search_box {
    display: none;
  }
}
@media screen and (max-width: 34.9375em) {
  .social_media_links {
    display: none;
  }
}
@media screen and (max-width: 47.4375em) {
  .social_media_share a.print {
    display: none;
  }
}
@media screen and (min-width: 61.875em) {
  .steuernews_article_slider {
    display: block;
    height: 16.125rem;
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    margin-bottom: 1.64em;
    z-index: 0;
  }
  .steuernews_article_slider .slide {
    display: -webkit-box;
    display: flex;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    visibility: hidden;
    z-index: 0;
  }
  .steuernews_article_slider .slide .image {
    min-width: 24.1875rem;
    width: 24.1875rem;
  }
  .steuernews_article_slider .slide .image img {
    border: 0.0625rem solid #e2e2e2;
  }
  .steuernews_article_slider .slide .description {
    align-content: stretch;
    -webkit-box-align: start;
            align-items: flex-start;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
            justify-content: center;
    max-width: 40.625rem;
    padding: 0 4.92em 1.64em 1.64em;
    width: 100%;
  }
  .steuernews_article_slider .slide .description .title {
    margin: 0;
    padding: 0;
    width: 100%;
  }
  .steuernews_article_slider .slide .description .sub_title {
    display: block;
    margin-bottom: 0.82em;
  }
  .steuernews_article_slider .slide .description p {
    width: 100%;
  }
  .steuernews_article_slider .slide_anchor:checked + .slide {
    opacity: 1;
    visibility: visible;
    z-index: 5;
  }
  .steuernews_article_slider .control_slides {
    align-content: center;
    -webkit-box-align: center;
            align-items: center;
    background: #0766b1;
    border-radius: 0.625rem;
    display: -webkit-box;
    display: flex;
    fill: #fff;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: nowrap;
    height: 3.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    line-height: 0;
    margin-top: -1.875rem;
    padding: 1.09333333em;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    width: 3.75rem;
    z-index: 15;
  }
  .steuernews_article_slider .control_slides svg {
    width: 1.125rem;
  }
  .steuernews_article_slider .control_slides:focus,
  .steuernews_article_slider .control_slides:hover {
    background-color: #0982e2;
    fill: #fff;
    outline: 0;
  }
  .steuernews_article_slider .prev_slide {
    left: 0;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .steuernews_article_slider .next_slide {
    right: 0;
  }
}
@media screen and (min-width: 30em) {
  .steuernews_preview .entry {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 47.5em) {
  .steuernews_preview .entry {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    width: 48%;
  }
}
@media screen and (min-width: 68.75em) {
  .steuernews_preview .entry {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 30em) {
  .steuernews_preview .entry .image {
    max-width: 8.75rem;
    padding-bottom: 0;
    padding-right: 1.64em;
  }
}
@media screen and (min-width: 35em) {
  .steuernews_preview .entry .image {
    max-width: 11.25rem;
  }
}
@media screen and (min-width: 47.5em) {
  .steuernews_preview .entry .image {
    max-width: 27.125rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    padding-bottom: 0.82em;
    padding-right: 0;
  }
}
@media screen and (min-width: 61.875em) {
  .steuernews_preview .entry .image {
    max-width: 32.5rem;
  }
}
@media screen and (min-width: 68.75em) {
  .steuernews_preview .entry .image {
    max-width: 13.75rem;
    padding-bottom: 0;
    padding-right: 1.64em;
  }
}
@media screen and (min-width: 78.125em) {
  .steuernews_preview .entry .image {
    max-width: 18.75rem;
  }
}
@media screen and (min-width: 35em) {
  .news_image {
    float: right;
    margin-left: 1.64em;
    max-width: 15.625rem;
  }
}
@media screen and (min-width: 47.5em) {
  .news_image {
    max-width: 21.875rem;
  }
}
@media screen and (min-width: 61.875em) {
  .news_image {
    max-width: 26.5625rem;
  }
}
@media screen and (min-width: 68.75em) {
  .news_image {
    max-width: 31.25rem;
  }
}
@media screen and (max-width: 29.9375em) {
  .steuernews_navigation li,
  .steuernews_issues li,
  .steuernews_keywords li {
    width: 100%;
  }
}
@media screen and (max-width: 29.9375em) {
  .steuernews_navigation a,
  .steuernews_issues a,
  .steuernews_keywords a {
    width: 100%;
  }
}
@media screen and (max-width: 47.4375em) {
  .steuernews_video_overview .entry {
    width: 30%;
  }
}
@media screen and (max-width: 29.9375em) {
  .steuernews_video_overview .entry {
    width: 100%;
  }
}
@media screen and (max-width: 47.4375em) {
  .steuernews_video_overview .more_steuernews_tv {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
}
@media screen and (max-width: 34.9375em) {
  .steuernews_video_overview .more_steuernews_tv {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
}
@media screen and (max-width: 47.4375em) {
  .steuernews_video_overview .more_steuernews_tv .entry {
    width: 30%;
  }
}
@media screen and (max-width: 34.9375em) {
  .steuernews_video_overview .more_steuernews_tv .entry {
    width: 100%;
  }
}
@media screen and (min-width: 68.75em) {
  .steuernews_sidebar .video_preview {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
            justify-content: space-between;
    align-content: stretch;
    -webkit-box-align: start;
            align-items: flex-start;
  }
}
@media screen and (min-width: 35em) {
  .steuernews_sidebar .video_preview .touch_link {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 68.75em) {
  .steuernews_sidebar .video_preview .touch_link {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: justify;
            justify-content: space-between;
    width: 31%;
  }
}
@media screen and (min-width: 35em) {
  .steuernews_sidebar .video_preview .touch_link .image {
    width: 17.5rem;
    padding-right: 2.132em;
  }
}
@media screen and (min-width: 61.875em) {
  .steuernews_sidebar .video_preview .touch_link .image {
    width: 23.75rem;
  }
}
@media screen and (min-width: 68.75em) {
  .steuernews_sidebar .video_preview .touch_link .image {
    width: 100%;
    padding-right: 0;
  }
  .steuernews_sidebar .video_preview .touch_link .image img {
    width: 100%;
  }
}
@media screen and (min-width: 68.75em) {
  .team_overview .photo {
    max-width: 21.25rem;
  }
}
@media screen and (min-width: 93.75rem) {
  .team_overview .photo {
    -webkit-transform: translateX(-3.125rem);
            transform: translateX(-3.125rem);
  }
}
@media screen and (min-width: 47.5em) {
  .video_preview .touch_link {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 47.5em) {
  .video_preview .touch_link .image {
    margin-bottom: 0;
    padding-right: 1.64em;
  }
}
@media screen and (max-width: 47.4375em) {
  .main_wrapper {
    padding: 1.148em 0 1.148em 0;
    min-height: 6.25rem;
  }
}
@media screen and (min-width: 160rem) {
  .page_wrapper::after,
  .page_wrapper::before {
    background-color: #fff;
    background-repeat: repeat;
    bottom: 0;
    box-shadow: inset 0 0 0.75rem rgba(0, 0, 0, 0.15);
    content: "";
    position: fixed;
    top: 0;
    z-index: 8999;
  }
  .page_wrapper::before {
    left: 0;
    margin-right: 80rem;
    right: 50%;
  }
  .page_wrapper::after {
    left: 50%;
    margin-left: 80rem;
    right: 0;
  }
}
@media screen and (min-width: 20rem) {
  .sitemap,
  .column_two_all,
  .column_two ul,
  .column_two p {
    -webkit-column-gap: calc(0.9375rem + 2.1875
                                     * (100vw - 20rem)
                                     / (78.125 - 20));
            column-gap: calc(0.9375rem + 2.1875
                                     * (100vw - 20rem)
                                     / (78.125 - 20));
  }
}
@media screen and (min-width: 61.875em) {
  .sitemap,
  .column_two_all,
  .column_two ul,
  .column_two p {
    -webkit-column-count: 2;
            column-count: 2;
  }
}
@media screen and (min-width: 78.125em) {
  .sitemap,
  .column_two_all,
  .column_two ul,
  .column_two p {
    -webkit-column-gap: 3.125rem;
            column-gap: 3.125rem;
  }
}
@media screen and (max-width: 68.6875em) {
  .hideme_smaller_xxlarge {
    display: none;
  }
}
@media print {
  .company_logo {
    display: block;
    width: 18.5rem;
  }
  .company_logo img {
    display: block;
    width: 18.5rem;
  }
  header {
    height: 5.3125rem !important;
  }
  .hideme_print,
  .cms_live_management {
    display: none !important;
  }
  .showme_print {
    display: inherit !important;
    height: auto !important;
    max-height: none !important;
  }
  * {
    background: transparent !important;
    border-color: black !important;
    color: black !important;
    text-shadow: none !important;
  }
  *:before {
    color: black !important;
  }
  @page {
    margin: 2cm;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
  }
  main a {
    text-decoration: underline;
  }
  main a.touch_link {
    margin-bottom: 1.64em;
    page-break-inside: avoid;
  }
  .table {
    overflow-y: inherit;
  }
  tr {
    page-break-inside: avoid;
  }
  dl {
    margin: 0;
  }
  dt {
    font-weight: 700;
    margin-bottom: 0.82em;
  }
  dd {
    margin: 0;
  }
  [role="tooltip"] {
    display: none;
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .news_entity .creation_date {
    display: none;
  }
  body {
    display: block;
    overflow-x: visible;
  }
  .page {
    display: block;
  }
  main {
    padding-top: 1.64em;
  }
  .company_logo img {
    width: 50%;
  }
  .qr_code_print {
    border-top: 0.0625rem solid;
    margin: 1.64em 0 0;
    padding: 0.82em 0 0;
    page-break-inside: avoid;
  }
  .qr_code_print:after {
    clear: both;
  }
  .qr_code_print:after,
  .qr_code_print:before {
    content: "";
    display: table;
  }
  .qr_code_print img {
    float: left;
  }
  .qr_code_print p {
    margin-left: 10rem;
  }
}
